/* eslint-disable max-len */
import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export const FallbackCheckIcon = createSvgIcon(
  <>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-452.000000, -1624.000000)" stroke="#000000">
        <g id="Extras" transform="translate(92.000000, 1576.000000)">
          <g id="3" transform="translate(238.000000, 48.000000)">
            <g
              id="suitable-for-copy"
              transform="translate(122.000000, 0.000000)"
            >
              <g id="Group-7" transform="translate(11.200000, 11.200000)">
                <path
                  d="M21.2011336,42.4 C9.51120736,42.4 0,32.8905035 0,21.2019835 C0,9.51119665 9.51064057,0 21.2011336,0 C32.8910598,0 42.4,9.51119665 42.4,21.2019835 C42.4005668,32.8905035 32.8910598,42.4 21.2011336,42.4 Z"
                  id="Stroke-1"
                  stroke="grey"
                  strokeWidth="3"
                ></path>
              </g>
              <path
                d="M40.8,28.8 L29.8782609,38.1232061 C29.670552,38.3074156 29.4085723,38.4 29.1460993,38.4 C28.8722788,38.4 28.599445,38.2997402 28.3882825,38.0997002 L23.2,33.1625025"
                id="Stroke-1-Copy-2"
                stroke="grey"
                strokeWidth="3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </>,
  'FallbackCheckIcon',
  '0 0 58 58'
);
