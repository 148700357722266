import { all, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as actionTypes from './actionTypes';
import fetchService from '../../../../../services/FetchService';

/*
 * Get ExploreMap Saga
 */
export function* getExploreMapSaga(action) {
  yield put(actions.getExploreMapStart());
  try {
    const response = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      yield put(actions.getExploreMapSuccess(response.data));
    } else {
      yield put(actions.getExploreMapFail(response.data));
    }
  } catch (error) {
    console.log('Error at Catch getExploreMapSaga', error);
    yield put(
      actions.getExploreMapFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchExploreMap() {
  yield all([takeLatest(actionTypes.GET_EXPLOREMAP, getExploreMapSaga)]);
}
