/*
  This will hold all the theming(Material theme Object) info for SiteA
  add updates to theme will reflect across website.
*/
import { createMuiTheme } from '@material-ui/core/styles';
import { palette } from './palette';
import { borders } from './borders';
import spacing from './spacing';
import shadows from './shadows';
import { getVariant, getFontFamily } from './utils';
import overrides from './overrides';

import { breakpoints, easing, arabicLang, hebrewLang } from './constants';
import { mixins } from './mixins';
import shape from './shape';

const theme = (selectedLang) => {
  // const selectedLang = 'ar';
  const isRtl = selectedLang === arabicLang || selectedLang === hebrewLang;
  const variants = getVariant(selectedLang);
  const fontFamily = getFontFamily(selectedLang);

  return createMuiTheme({
    palette: {
      ...palette,
    },
    typography: {
      fontFamily: fontFamily.primaryRegular,
      ...variants,
    },
    custom: {
      fontFamily: fontFamily
    },
    shadows,
    breakpoints: breakpoints,
    spacing,
    easing,
    borders,
    mixins: {
      ...mixins(isRtl),
    },
    shape: {
      ...shape,
    },
    direction: isRtl ? 'rtl' : 'ltr',
    isDirectionRTL: isRtl,
    // transitions: {
    //   // So no more default MUI transition everywhere
    //   create: () => 'none',
    // },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*, *::before, *::after': {
            boxSizing: 'border-box',
          },
          html: {
            WebkitFontSmoothing: 'auto',
          },
          body: {
            color: 'inherit',
            margin: '0',
            backgroundColor: palette.common.White,
            overflowX: 'hidden',
            position: 'relative',
          },
          '.hero-banner-section': {
            width: '100%',
            height: '100vh',
            minHeight: '100vh',
            '& .slick-thumb li': {
              width: '192px',
              height: '118px',
            },
          },
          '.segment-title': {
            position: 'relative',
            marginBottom: '0',
          },
          'a:focus': {
            '-webkit-text-fill-color': 'inherit',
            outlineColor: palette.common.SeaPort,
          },
          '.img-in-exp-editor': {
            maxWidth: '100%',
          },
          '.preloadImages': {
            width: '1px',
            height: '1px',
            left: '0',
            position: 'absolute',
            visibility: 'hidden',
          },
          '.marginBottom-0': {
            marginBottom: '0 !important',
          },
        },
      },
      ...overrides(isRtl, selectedLang),
    },

    props: {
      MuiButtonBase: {
        disableRipple: true, // No more ripple, on the whole application
      },
      MuiTab: {
        disableRipple: true,
      },
    },
  });
};

export default theme;
