import React, { Component } from 'react';

/*
  This HOC is error boundary. Wrap your newly created component in common with withErrorBoundary
  Input param: wrap component like withErrorBoundary(withSitecoreContext()(withNamespaces()(Breadcrumbs)))
  Output: If any error will occur, it will return null. If there is no error, It will return component itself.
*/
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, err: undefined };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

const withErrorBoundary = WrappedComponent =>
  class Wrapper extends Component {
    render() {
      let props = { ...this.props };
      const authorFields = ['fields', 'params'];
      authorFields.forEach(authorField => {
        if (!props[authorField]) {
          props[authorField] = {};
        }
      });
      let element = <WrappedComponent {...props} />;
      return (
        <ErrorBoundary>
          {element}
        </ErrorBoundary>
      );
    }
  };
export default withErrorBoundary;
