import { pxToRem } from '../../../../styles/themes/vad/constants';

export const styles = theme => {
  const colors = theme.palette.colors || {};
  const textColors = {};

  Object.keys(colors).forEach(color => {
    /** For Default */
    textColors[`text-${color}`] = {
      color: colors[color].contrastText,
      '& svg': {
        color: colors[color].contrastText,
      },
    };
  });
  return {
    /* Styles applied to the root element. */
    root: {
      margin: 0,
    },
    /* Styles applied to the root element if `variant="small"`. */
    small: theme.typography.small,
    /* Styles applied to the root element if `variant="body1"`. */
    body1: theme.typography.body1,
    /* Styles applied to the root element if `variant="body1Regular"`. */
    body1Regular: theme.typography.body1Regular,
    /* Styles applied to the root element if `variant="body1Bold"`. */
    body1Bold: theme.typography.body1Bold,
    /* Styles applied to the root element if `variant="body2"`. */
    /* Styles applied to the root element if `variant="body1Bold22"`. */
    body1Bold22: theme.typography.body1Bold22,
    /* Styles applied to the root element if `variant="body1a"`. */
    body1a: theme.typography.body1a,
    /* Styles applied to the root element if `variant="body2"`. */
    body2: theme.typography.body2,
    /* Styles applied to the root element if `variant="body2Light"`. */
    body2Light: theme.typography.body2Light,
    /* Styles applied to the root element if `variant="body2Bold"`. */
    body2Bold: theme.typography.body2Bold,
    /* Styles applied to the root element if `variant="body3"`. */
    body3: theme.typography.body3,
    /* Styles applied to the root element if `variant="body3Bold"`. */
    body3Bold: theme.typography.body3Bold,
    /* Styles applied to the root element if `variant="body5"`. */
    body5: theme.typography.body5,
    /* Styles applied to the root element if `variant="body4Bold"`. */
    body4Bold: theme.typography.body4Bold,
    /* Styles applied to the root element if `variant="quote"`. */
    quote: theme.typography.quote,
    /* Styles applied to the root element if `variant="caption"`. */
    caption: theme.typography.caption,
    /* Styles applied to the root element if `variant="button"`. */
    button: theme.typography.button,
    /* Styles applied to the root element if `variant="radioButton"`. */
    radioButton: theme.typography.radioButton,
    /* Styles applied to the root element if `variant="h1"`. */
    h1: theme.typography.h1,
    /* Styles applied to the root element if `variant="h2"`. */
    h2: theme.typography.h2,
    /* Styles applied to the root element if `variant="h3"`. */
    h3: theme.typography.h3,
    /* Styles applied to the root element if `variant="h4"`. */
    h4: theme.typography.h4,
    /* Styles applied to the root element if `variant="h5"`. */
    h5: theme.typography.h5,
    /* Styles applied to the root element if `variant="h51"`. */
    h51: theme.typography.h51,
    /* Styles applied to the root element if `variant="h5Bold"`. */
    h5Bold: theme.typography.h5Bold,
    /* Styles applied to the root element if `variant="h52"`. */
    h52: theme.typography.h52,
    /* Styles applied to the root element if `variant="h53Bold"`. */
    h53Bold: theme.typography.h53Bold,
    /* Styles applied to the root element if `variant="subTitle"`. */
    subTitle: theme.typography.subTitle,
    /* Styles applied to the root element if `variant="subTitleBold"`. */
    subTitleBold: theme.typography.subTitleBold,
    /* Styles applied to the root element if `variant="labelLight"`. */
    labelLight: theme.typography.labelLight,
    /* Styles applied to the root element if `variant="labelBold"`. */
    labelBold: theme.typography.labelBold,
    /* Styles applied to the root element if `variant="labelRegular"`. */
    labelRegular: theme.typography.labelRegular,
    /* Styles applied to the root element if `variant="labelBlack"`. */
    labelBlack: theme.typography.labelBlack,
    /* Styles applied to the root element if `variant="labelBlack2"`. */
    labelBlack2: theme.typography.labelBlack2,
    /* Styles applied to the root element if `variant="cardTitle1"`. */
    cardTitle1: theme.typography.cardTitle1,
    /* Styles applied to the root element if `variant="cardTitle2"`. */
    cardTitle2: theme.typography.cardTitle2,
    /* Styles applied to the root element if `variant="overline"`. */
    overline: theme.typography.overline,
    /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
    srOnly: {
      position: 'absolute',
      height: 1,
      width: 1,
      overflow: 'hidden',
    },
    /* Styles applied to the root element if `align="left"`. */
    alignLeft: {
      textAlign: 'left',
    },
    /* Styles applied to the root element if `align="center"`. */
    alignCenter: {
      textAlign: 'center',
    },
    /* Styles applied to the root element if `align="right"`. */
    alignRight: {
      textAlign: 'right',
    },
    /* Styles applied to the root element if `align="justify"`. */
    alignJustify: {
      textAlign: 'justify',
    },
    /* Styles applied to the root element if `nowrap={true}`. */
    noWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    /* Styles applied to the root element if `gutterBottom={true}`. */
    gutterBottom: theme.typography.gutterBottom,
    /* Styles applied to the root element if `paragraph={true}`. */
    paragraph: {
      marginBottom: pxToRem(16),
    },
    /* Styles applied to the root element if `color="inherit"`. */
    colorInherit: {
      color: 'inherit',
    },
    ...textColors,
    /* Styles applied to the root element if `color="primary"`. */
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    /* Styles applied to the root element if `color="secondary"`. */
    colorSecondary: {
      color: theme.palette.secondary.main,
    },
    /* Styles applied to the root element if `color="textPrimary"`. */
    colorTextPrimary: {
      color: theme.palette.text.primary,
    },
    /* Styles applied to the root element if `color="textSecondary"`. */
    colorTextSecondary: {
      color: theme.palette.text.secondary,
    },
    /* Styles applied to the root element if `display="inline"`. */
    displayInline: {
      display: 'inline',
    },
    /* Styles applied to the root element if `display="block"`. */
    displayBlock: {
      display: 'block',
    },
    /* Styles applied to the root element if `display="block"`. */
    wordBreak: {
      wordBreak: 'break-word',
    },
    scrollAnimateWrapper: {
      backfaceVisibility: 'hidden',
    },
    scrollAnimate: {
      display: 'inline-block',
    },
  };
};
