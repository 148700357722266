/* 
  This file defines color palette
*/

import { mixins } from './mixins';

//Base Colors
const AbuDhabiRed = 'rgb(194, 0, 47)'; //#C2002F
const AbuDhabiOrange = 'rgb(255 135 7)'; //#FF8707
const ADCalendar = 'rgb(105, 15, 168)'; //#690FA8
const DarkTurquoise = 'rgb(0, 96, 95)'; //#00605F
// Tabs and Content card bar
const SeaGreen = '#009086'; // rgb(0, 144, 134)

//Extended Colors
const GlitteringSand = '#b4a7a2';
const DesrtSunset = 'rgb(162, 80, 34)'; //#A25022
const SandDune = 'rgb(202, 155, 74)'; //#CA9B4A
const SandDuneLight = 'rgb(218,187,153)'; //#DABB99
const Sunrise = 'rgb(239, 189, 71)'; //#EFBD47
const Highlight = 'rgb(255, 233, 0)'; //#FFE900
const Sandstone = 'rgb(202, 192, 182)'; //#CAC0B6
const DesertDusk = 'rgb(233, 211, 215)'; //#E9D3D7
const DateBrown = 'rgb(82, 45, 36)'; //#522D24
const PalmForest = 'rgb(62, 69, 25)'; //#3E4519
const OasisGreen = 'rgb(135, 150, 55)'; //#879637
const SpringGreen = 'rgb(199, 210, 138)'; //#C7D28A
const WovenPalm = 'rgb(214, 212, 174)'; //#D6D4AE
const SeaPort = 'rgb(0, 65, 101)'; //#004165
const CategortTagColor = 'rgb(58, 52, 138)'; //#3A348A
const Mangroves = 'rgb(21, 101, 112)'; //#156570
const MarineBlue = 'rgb(0, 102, 161)'; //#0066A1
const ArabianGulf = 'rgb(84, 130, 171)'; //#5482AB
const TurquoiseShore = 'rgb(183, 206, 196)'; //#B7CEC4
const ContemporaryChrome = 'rgb(94, 106, 113)'; //#5E6A71
const IlluminatedSkyline = 'rgb(134, 131, 164)'; //#8683A4
const EnergyBlue = 'rgb(125, 154, 170)'; //#7D9AAA
const CityscapeGrey = 'rgb(173, 175, 175)'; //#ADAFAF
const QuiteMarble = 'rgb(218, 215, 203)'; //#DAD7CB
const MysticGrey = 'rgb(221, 223, 222)'; //#DDDFDE
const LightGrey = 'rgb(170 170 170)'; //#AAAAAA
const Grey1 = 'rgb(120, 123, 125)'; // #787B7D
const defaultBgGrey = 'rgb(223, 229, 232)'; //#DFE5E8
const SeashellWhite = 'rgb(250 249 245)'; //#FAF9F5
const RedRibbon = 'rgb(231, 15, 67)'; // #E70F43
const TripAdvisorThemeColor = 'rgb(0, 170, 108)'; // #00AA6C
const UnselectedColor = 'rgb(112, 108, 108)'; // #706C6C
const SlateGray = 'rgb(129, 135, 137)'; // #818789
const datePickerTextColor = 'rgb(130, 136, 148)'; // rgb(130, 136, 148)
const lightBGBlue = '#C9DBE4' //rgb(201,219,228)

//Other Colors
const RaisinBlack = 'rgba(38,38,38,1)'; // this is used for gradient creation in header
const MostlyBlack = 'rgba(2, 2, 2, 0)'; // this is used for gradient creation in header
const LightGray = '#fcfcfc'; // This is used for bg color privacy policy component
const ErrorRed = 'rgb(255, 12, 12)';
const black3 = '#f7f7f7';

const DisabledGrey = '#dddede';
const ActiveGray = '#97A1A7';
const BGGray = '#EEEFEF';
const DKGray = '#444444';
const BlackFadded = 'rgba(0, 0, 0, 0.8)';
const Black4 = 'rgb(51, 51, 51)'; // #333333
const White = 'rgb(255, 255, 255)'; // #FFFFFF
const NearWhite = 'rgb(246, 246, 246)';
const NearWhite2 = 'rgb(247, 247, 247)';
const OverlayGray = 'rgb(85 85 85)';
const LightSilver = 'rgb(216, 216, 216)';
const PeacockBlue = '#003451' // rgb(0,52,81)

// Brand 2022 Colors
const Axolotl = '#577360';
const Pompadour = '#4C0C47';
const HalfDutchWhite = '#FCEFDE';

const Amarnath = (alpha = 1000) => `rgba(239, 81, 87, ${alpha / 1000})`; // rgb(239, 81, 87)
const BGGray179 = (alpha = 1000) => `rgba(179, 179, 179, ${alpha / 1000})` // rgb(179, 179, 179)
const DarkBlack = (alpha = 1000) => `rgba(15, 28, 24, ${alpha / 1000})` // rgb(15, 28, 24)
const LightGrayCCC = (alpha = 1000) => `rgba(211,211,211, ${alpha / 1000})` // rgb(211,211,211)
const GrayAAA = (alpha = 1000) => `rgba(170, 170, 170, ${alpha / 1000})` // rgb(170, 170, 170)
const DisabledFavIcon = (alpha = 1000) => `rgba(32, 29, 29, ${alpha / 1000})` // rgb(32, 29, 29)
const MediumGray = (alpha = 1000) => `rgbaa(151, 151, 151, ${alpha / 1000})` // rgb(151, 151, 151)
const MediumGray2 = (alpha = 1000) => `rgba(217,217,217, ${alpha / 1000})` // rgb(217,217,217)
const TealBlue = (alpha = 1000) => `rgba(0,65,101, ${alpha / 1000})` // rgb(0,65,101)
const Black = (alpha = 1000) => mixins().Black(alpha) // rgb(0,65,101)


// This is default ellipsis after two lines
// but you can pass line in params as per your need
const ellipsis = (line = 2) => {
  // line is not a number
  if (line === 'none' || line === 'nan') {
    // revert line clamp
    return {
      '-webkit-line-clamp': 'unset',
      'line-clamp': 'unset',
      '-webkit-box-orient': 'unset',
      display: 'unset',
      overflow: 'unset',
    }
  }
  return {
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': line,
    'line-clamp': line,
    overflow: 'hidden',
  }
};

export const common = {
  ellipsis: ellipsis,
  raisinBlack: RaisinBlack,
  mostlyBlack: MostlyBlack,
  BlackFadded,
  Black4,
  White,
  AbuDhabiRed,
  AbuDhabiOrange,
  ADCalendar,
  DarkTurquoise,
  GlitteringSand,
  SeaPort,
  CategortTagColor,
  SandDune,
  DesrtSunset,
  Sunrise,
  Highlight,
  Sandstone,
  DesertDusk,
  DateBrown,
  PalmForest,
  OasisGreen,
  SpringGreen,
  WovenPalm,
  Mangroves,
  MarineBlue,
  ArabianGulf,
  TurquoiseShore,
  ContemporaryChrome,
  IlluminatedSkyline,
  EnergyBlue,
  CityscapeGrey,
  QuiteMarble,
  MysticGrey,
  LightGrey,
  Grey1,
  defaultBgGrey,
  SeashellWhite,
  RedRibbon,
  TripAdvisorThemeColor,
  UnselectedColor,
  SlateGray,
  ActiveGray,
  BGGray,
  DKGray,
  OverlayGray,
  LightSilver,
  NearWhite,
  NearWhite2,
  SeaGreen,
  DisabledGrey,
  Amarnath,
  DarkBlack,
  LightGrayCCC,
  GrayAAA,
  DisabledFavIcon,
  MediumGray2,
  TealBlue,
  Black,
  Axolotl,
  Pompadour,
  HalfDutchWhite,
};

export const background = {
  default: black3,
  transparent: 'transparent',
  yellow10: 'rgb(253, 248, 236)',
  seaport10: '#f0eeee',
  lightGray: LightGray,
  SandDuneLight,
  BGGray179,
  InfoBG: lightBGBlue,
};

export const border = {
  divider: mixins().Grey(100),
  error: ErrorRed,
  weatherTableBorder: MediumGray,
  mapCategory: PeacockBlue,
};

export const text = {
  primary: mixins().Black(),
  secondary: mixins().AbuDhabiOrange(),
  tertiary: 'rgb(112, 108, 108)',
  tertiary1: 'rgb(120, 123, 125)',
  disabled: mixins().Black(200),
  defaultText: mixins().Black(400),
  tabDefaultText: mixins().Black(500),
  error: ErrorRed,
  datePicker: datePickerTextColor,
  sectionHeading: PeacockBlue,
};

export const action = {
  hover: mixins().Black(40),
  hoverOpacity: '0.5',
  disabled: mixins().Black(200),
  disabledBackground: black3,
  disabledOpacity: '0.3',
};

export const categoryTagaction = {
  hover: mixins().Black(40),
  hoverOpacity: '0.5',
  disabled: mixins().Black(200),
  disabledBackground: 'transparent',
  disabledOpacity: '0.3',
};

export const secondary = {
  main: mixins().White(),
  contrastText: mixins().Black(),
};

export const primary = {
  main: mixins().Black(),
  contrastText: mixins().White(),
};

// Color Paletts for Categories in Map
// Color is mapped to the DisplayName value of Category
export const categories = {
  Activities: '#879637',
  Hotels: '#7D9AAA',
  Events: '#690FA8',
  Restaurants: '#8683A4',
  Attractions: '#690FA8',
}

export const palette = {
  primary,
  secondary,
  text,
  common,
  action,
  categoryTagaction,
  background,
  border,
  categories,
};
