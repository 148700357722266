import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiAlert-root': {
      padding: `${pxToRem(15)} ${pxToRem(16)}`,
      color: theme.mixins.Black(),
      backgroundColor: theme.palette.background.yellow10,
      border: 0,
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        padding: `${pxToRem(32)} ${pxToRem(92)} ${pxToRem(30)}`,
        minHeight: pxToRem(88),
        alignItems: 'inherit',
      },

      '&.MuiPaper-root': {
        '& > .MuiAlert-icon': {
          color: theme.mixins.Black(),
          ...theme.mixins.marginRight(pxToRem(12)),
          marginRight: theme.isDirectionRTL && 0,
          padding: '0',
          [theme.breakpoints.up('lg')]: {
            padding: `${pxToRem(2)} 0 0`,
          },
        },
      },
      '& > .MuiAlert-message': {
        padding: 0,
      },
      '& .MuiAlert-action': {
        ...theme.mixins.marginLeft('auto'),
        ...theme.mixins.paddingLeft(pxToRem(16)),
        paddingLeft: theme.isDirectionRTL && 0,
        marginLeft: theme.isDirectionRTL && 'inherit',
        [theme.breakpoints.down('md')]: {
          alignItems: 'baseline',
        },
      },
      '& .ctaBtn': {
        '& .MuiButton-startIcon': {
          padding: 0,
          ...theme.mixins.marginLeft(pxToRem(-8)),
        },
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          justifyContent: 'flex-start',
          textDecoration: 'underline',
          '& .MuiButton-startIcon': {
            padding: 0,
          },
        },
      },
    },
  },
}));

export default useStyles;
