import * as actionTypes from './actionTypes';
/**
 *  Initial ExploreMap Reducer
 */
const initialState = {
  loading: true,
  error: null,
  message: '',
  geoJsonData: {
    features: [],
    crs: null,
  },
  destinations: [],
  features: [],
  places: [],
  completePlaces: [],
  selectedCategories: [],
  selectedCellID: null,
  isCellReselected: false,
  mapStep: 0,
  placesInView: [],
  isMapPanning: false,
  shouldMapZoom: false,
  clearAllClick: false,
};
/**
 * On Get ExploreMap Start Action
 */
const getExploreMapStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

/**
 * On Get ExploreMap Success Action
 */
const getExploreMapSuccess = (state, action) => {
  const filteredFeatures = action.payload.features.filter(
    feature => feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates[0] > 0 && feature.properties.name
  )
  const places = filteredFeatures.map(feat => {
    return {
      ...feat.properties,
      coordinates: feat.geometry.coordinates
    }
  })
  const completePlaces = places;
  return { ...state, geoJsonData: action.payload, features: filteredFeatures, places, completePlaces, error: null, loading: false };
};

/**
 * On Get ExploreMap Fail Action
 */
const getExploreMapFail = (state, action) => {
  return { ...state, error: true, loading: false, message: action.message };
};

const updateSelectedCategories = (state, action) => {
  const filteredFeatures = state.features.filter(
    feature => feature.geometry &&
      feature.geometry.coordinates &&
      feature.geometry.coordinates[0] > 0 &&
      feature.properties.name &&
      action.payload.length > 0 &&
      action.payload.includes((feature.properties.category)
      )
  )

  let places = []
  let features = []
  if (filteredFeatures.length > 0) {
    features = filteredFeatures;
    // } else {
    //   features = state.features;
  }
  places = features.map(feat => {
    return {
      ...feat.properties,
      coordinates: feat.geometry.coordinates
    }
  })

  return {
    ...state, selectedCategories: action.payload, geoJsonData: {
      crs: state.geoJsonData?.crs,
      features,
      type: "FeatureCollection"
    }, places,
  };
};

const updateGeoJSONData = (state, action) => {
  const array = action.payload.features ? action.payload.features : action.payload;
  const features = array.map(feat => {
    return {
      geometry: {
        coordinates: feat.coordinates
      },
      properties: feat
    }
  })
  return {
    ...state, places: array, geoJsonData: {
      crs: state.geoJsonData?.crs,
      features,
      type: "FeatureCollection"
    },
  };

}

const updatePlacesState = (state, action) => {
  return {
    ...state,
    placesInView: action.payload
  };
}

const setReselectedStatus = (state, action) => {
  return {
    ...state,
    isCellReselected: state.selectedCellID == action.payload.itemId ? true : false,
    selectedCellID: state.selectedCellID == action.payload.itemId ? null : action.payload.itemId
  };
}

const setMapStep = (state, action) => {
  return {
    ...state,
    mapStep: action.payload
  };
}

const setMapZoomStatus = (state, action) => {
  return {
    ...state,
    shouldMapZoom: action.payload
  };
}

const setIsMapPanning = (state, action) => {
  return {
    ...state,
    isMapPanning: action.payload
  };
}
const setClearingAll = (state, action) => {
  return {
    ...state,
    clearAllClick: action.payload
  };
}
/**
 * Update ExploreMap Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAP_STEP:
      return setMapStep(state, action);
    case actionTypes.UPDATE_GEOJSON_DATA:
      return updateGeoJSONData(state, action);
    case actionTypes.UPDATE_PLACES:
      return updatePlacesState(state, action);
    case actionTypes.UPDATE_SELECTED_CATEGORIES:
      return updateSelectedCategories(state, action);
    case actionTypes.GET_EXPLOREMAP_START:
      return getExploreMapStart(state, action);
    case actionTypes.GET_EXPLOREMAP_SUCCESS:
      return getExploreMapSuccess(state, action);
    case actionTypes.GET_EXPLOREMAP_FAIL:
      return getExploreMapFail(state, action);
    case actionTypes.SET_RESELECTED_STATUS:
      return setReselectedStatus(state, action);
    case actionTypes.SHOULD_MAP_ZOOM:
      return setMapZoomStatus(state, action);
    case actionTypes.SET_MAP_PANNING:
      return setIsMapPanning(state, action);
    case actionTypes.SET_CLEARING_ALL:
      return setClearingAll(state, action);
    default:
      return state;
  }
};
