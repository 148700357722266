export const mixins = (isRtl = false) => ({
  right: unit =>
    isRtl
      ? {
        left: unit,
      }
      : {
        right: unit,
      },

  left: unit =>
    isRtl
      ? {
        right: unit,
      }
      : {
        left: unit,
      },

  positionLR: (leftUnit, rightUnit) =>
    isRtl
      ? {
        left: rightUnit,
        right: leftUnit,
      }
      : {
        left: leftUnit,
        right: rightUnit,
      },

  borderLeft: unit =>
    isRtl
      ? {
        borderRight: unit,
      }
      : {
        borderLeft: unit,
      },
  borderRight: unit =>
    isRtl
      ? {
        borderLeft: unit,
      }
      : {
        borderRight: unit,
      },

  marginRight: unit =>
    isRtl
      ? {
        marginLeft: unit,
      }
      : {
        marginRight: unit,
      },

  marginLeft: unit =>
    isRtl
      ? {
        marginRight: unit,
      }
      : {
        marginLeft: unit,
      },

  marginLR: (left, right) =>
    isRtl
      ? {
        marginLeft: right,
        marginRight: left,
      }
      : {
        marginLeft: left,
        marginRight: right,
      },

  paddingLeft: unit =>
    isRtl
      ? {
        paddingRight: unit,
      }
      : {
        paddingLeft: unit,
      },
  paddingRight: unit =>
    isRtl
      ? {
        paddingLeft: unit,
      }
      : {
        paddingRight: unit,
      },

  paddingLR: (left, right) =>
    isRtl
      ? {
        paddingLeft: right,
        paddingRight: left,
      }
      : {
        paddingLeft: left,
        paddingRight: right,
      },

  direction: () =>
    isRtl
      ? {
        direction: 'rtl',
      }
      : {
        direction: 'ltr',
      },

  textLeft: () => (isRtl ? 'right' : 'left'),
  textRight: () => (isRtl ? 'left' : 'right'),

  flexDirection: () => (isRtl ? 'row-reverse' : 'row'),
  rotate180: () => (isRtl ? 'rotate(0)' : 'rotate(180deg)'),
  rotate0: () => (isRtl ? 'rotate(180deg)' : 'rotate(0)'),
  translateX: angle => (isRtl ? -angle : angle),

  // Style Guide Colors
  // recommended opacity weight is 100, 200 ...to 900
  White: (opacityWeight = 1000) => `rgba(255,255,255, ${opacityWeight / 1000})`,
  AntiqueWhite: (opacityWeight = 1000) => `rgb(247, 247, 247, ${opacityWeight / 1000})`,
  LightOrange: (opacityWeight = 1000) => `rgb(252, 239, 222, ${opacityWeight / 1000})`,
  Black: (opacityWeight = 1000) => `rgba(0, 0, 0, ${opacityWeight / 1000})`,
  Grey: (opacityWeight = 1000) => `rgba(86, 90, 92, ${opacityWeight / 1000})`,
  DisabledGrey: (opacityWeight = 1000) => `rgba(187, 189, 190, ${opacityWeight / 1000})`,
  LightGrey: (opacityWeight = 1000) => `rgba(102, 102, 102, ${opacityWeight / 1000})`,
  AbuDhabiRed: (opacityWeight = 1000) =>
    `rgba(194, 0, 47, ${opacityWeight / 1000})`,
  AbuDhabiOrange: (opacityWeight = 1000) =>
    `rgba(255, 135, 7, ${opacityWeight / 1000})`,
  SeaGreen: (opacityWeight = 1000) =>
    `rgba(0, 144, 134, ${opacityWeight / 1000})`, // #009086
  RussianViolet: (opacityWeight = 1000) =>
    `rgba(76, 12, 71, ${opacityWeight / 1000})`, // #4c0c47
  milkWhite: (opacityWeight = 1000) =>
    `rgba(218, 215, 203, ${opacityWeight / 1000})`,
  neroBlack: (opacityWeight = 1000) =>
    `rgba(25, 25, 25, ${opacityWeight / 1000})`,
  ADCalendar: (opacityWeight = 1000) =>
    `rgba(105, 15, 168, ${opacityWeight / 1000})`,
  DarkTurquoise: (opacityWeight = 1000) =>
    `rgba(0, 96, 95, ${opacityWeight / 1000})`,
  CategortTagColor: (opacityWeight = 1000) =>
    `rgba(58, 52, 138, ${opacityWeight / 1000})`,
  SolidGrey: (opacityWeight = 1000) =>
    `rgba(120, 123, 125, ${opacityWeight / 1000})`,
  BluishGray: (opacityWeight = 1000) => `rgba(227,233,243, ${opacityWeight / 1000})`, // rgb(227,233,243)
  DarkBlack: (opacityWeight = 1000) => `rgba(38, 38, 38, ${opacityWeight / 1000})`, // rgb(38, 38, 38)
  Dark23: (opacityWeight = 1000) => `rgba(23, 23, 23, ${opacityWeight / 1000})`, // rgb(23, 23, 23)
  HalfDutchWhite: (opacityWeight = 1000) =>
    `rgba(252, 239, 222, ${opacityWeight / 1000})`,
});
