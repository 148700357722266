//import { common } from './palette';  #TODO
import { fontFamilyEN as fontFamily, fontSize } from './font';
import { desktopMedia, pxToRem } from './constants';

export const variantItemsZH = {
  h1: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: fontSize.s40Rem,
    lineHeight: '100%',
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s120Rem,
      lineHeight: '100%',
    },
  },
  h2: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: fontSize.s32Rem,
    lineHeight: fontSize.s40Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s72Rem,
      lineHeight: fontSize.s80Rem,
    },
  },
  h3: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: fontSize.s24Rem,
    lineHeight: fontSize.s32Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s64Rem,
      lineHeight: fontSize.s72Rem,
    },
  },
  h4: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: fontSize.s22Rem,
    lineHeight: fontSize.s32Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s48Rem,
      lineHeight: fontSize.s56Rem,
    },
  },
  h5: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: fontSize.s20Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s32Rem,
      lineHeight: fontSize.s40Rem,
    },
  },
  h51: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryRegular,
    fontSize: fontSize.s24Rem,
    lineHeight: fontSize.s32Rem,
    letterSpacing: '0',
    fontWeight: 'normal',
  },
  h5Bold: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBold,
    fontWeight: 'normal',
    fontSize: fontSize.s20Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s32Rem,
      lineHeight: fontSize.s40Rem,
    },
  },
  h53Bold: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBold,
    fontWeight: 700,
    fontSize: fontSize.s24Rem,
    lineHeight: fontSize.s32Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s32Rem,
      lineHeight: fontSize.s40Rem,
    },
  },
  body1: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: fontSize.s16Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s24Rem,
      lineHeight: fontSize.s32Rem,
    },
  },
  body1Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: fontSize.s16Rem,
    lineHeight: fontSize.s24Rem,
    [desktopMedia]: {
      fontSize: fontSize.s24Rem,
      lineHeight: fontSize.s32Rem,
    },
  },
  body2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: fontSize.s14Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s16Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  body2Light: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: fontSize.s14Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s16Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  body2Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: fontSize.s14Rem,
    lineHeight: fontSize.s24Rem,
    [desktopMedia]: {
      fontSize: fontSize.s16Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  body3: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s14Rem,
      lineHeight: fontSize.s16Rem,
    },
  },
  body3Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    [desktopMedia]: {
      fontSize: fontSize.s14Rem,
      lineHeight: fontSize.s16Rem,
    },
  },
  body5: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: fontSize.s14Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
  },
  body4Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: fontSize.s16Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
  },
  labelLight: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0',
  },
  labelBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0',
  },
  labelBlack: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBlack,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0.5',
  },
  labelBlack2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBlack,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0.5px',
  },
  labelRegular: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: fontSize.s12Rem,
    lineHeight: fontSize.s16Rem,
    letterSpacing: '0',
  },
  subTitle: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: fontSize.s16Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s18Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  subTitleBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: fontSize.s16Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: fontSize.s18Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  cardTitle1: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: fontSize.s24Rem,
    lineHeight: fontSize.s32Rem,
    letterSpacing: '0',
  },
  cardTitle2: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: fontSize.s20Rem,
    lineHeight: fontSize.s24Rem,
    letterSpacing: '0',
  },
  gutterBottom: {
    marginBottom: pxToRem(24),
  },
};
