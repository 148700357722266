import * as actionTypes from './actionTypes';
import { checkboxType, calendarType, dateRangeKeys } from '../constants';

/**
 *  Initial State filtersData Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
};
/**
 * On Get filtersList Start Action
 */
const getFilterInfoStart = state => {
  return { ...state, error: null, loading: true };
};

/**
 * On Get filtersList Success Action
 */
const getFilterInfoSuccess = (state, action) => {
  return {
    ...state,
    data: [...action.payload.results],
    totalResults: action.payload.apiResult.totalResults,
    error: null,
    loading: false,
  };
};

/**
 * On Get filtersList Fail Action
 */
const getFilterInfoFail = (state, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    message: action.message,
  };
};

/**
 * On Get getFilterUpdatedState
 */
const getFilterUpdatedState = (state, action) => {
  let stateData = state.data;
  stateData.map(value => {
    if (value.fieldName.value === action.data.name) {
      if (value.type === checkboxType) {
        value.listItems.map(list => {
          if (list.fields.key.value === action.data.value) {
            list.fields.selected.value = action.data.checked;
          }

          return list;
        });
      } else {
        value.selectedValue.value = action.data.value;
      }
    } else if (
      value.type === calendarType &&
      (action.data.name === dateRangeKeys.startDate ||
        action.data.name === dateRangeKeys.endDate)
    ) {
      if (action.data.name === dateRangeKeys.startDate) {
        value.startDate.value = action.data.value;
        if (action.data.checked === false) {
          value.endDate.value = action.data.value;
        }
      } else {
        value.endDate.value = action.data.value;
      }
    }

    return value;
  });
  return {
    ...state,
    data: [...stateData],
    error: null,
    loading: false,
  };
};

/**
 * On Get getMultipleFilterUpdatedState
 */
const getMultipleFilterUpdatedState = (state, action) => {
  let stateData = state.data;
  action.data.forEach(data => {
    stateData.map(value => {
      if (value.fieldName.value === data.key) {
        if (value.type === checkboxType) {
          value.listItems.map(list => {
            if (list.fields.key.value === data.value) {
              list.fields.selected.value = true;
            }

            return list;
          });
        } else {
          value.selectedValue.value = data.value;
        }
      }

      return value;
    });
  });
  return {
    ...state,
    data: [...stateData],
    error: null,
    loading: false,
  };
};

/**
 * On Get getFilterUpdatedStateMobile which returns state received to filtersData state
 */
const getFilterUpdatedStateMobile = (state, action) => {
  return {
    ...state,
    data: [...action.data],
    error: null,
    loading: false,
  };
};

/**
 * On Get getClearedFilterState
 */
const getClearedFilterState = state => {
  let stateData = state.data;
  stateData.map(value => {
    if (value.type === checkboxType) {
      value.listItems.map(list => {
        list.fields.selected.value = false;

        return list;
      });
    } else if (value.type === calendarType) {
      if (value.startDate && value.startDate.value) {
        value.startDate.value = '';
      }
      if (value.endDate && value.endDate.value) {
        value.endDate.value = '';
      }
    } else {
      value.selectedValue.value = '';
    }

    return value;
  });
  return {
    ...state,
    data: [...stateData],
    error: null,
    loading: false,
  };
};

/**
 * On Get getUpdatedFilterStateFromQuery
 */
const getUpdatedFilterStateFromQuery = (state, action) => {
  return {
    ...state,
    data: [...action.data],
    error: null,
    loading: false,
  };
};

/**
 * Update filtersList Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERSINFO_START:
      return getFilterInfoStart(state);
    case actionTypes.GET_FILTERSINFO_SUCCESS:
      return getFilterInfoSuccess(state, action);
    case actionTypes.GET_FILTERSINFO_FAIL:
      return getFilterInfoFail(state, action);
    case actionTypes.UPDATE_FILTERSINFO_DATA:
      return getFilterUpdatedState(state, action);
    case actionTypes.UPDATE_QUERY_FILTERSINFO_DATA:
      return getUpdatedFilterStateFromQuery(state, action);
    case actionTypes.CLEAR_SELECTED_FILTER_DATA:
      return getClearedFilterState(state);
    case actionTypes.UPDATE_SELECTED_FILTER_DATA_MOBILE:
      return getFilterUpdatedStateMobile(state, action);
    case actionTypes.UPDATE_MULTIPLE_FILTERSINFO_DATA:
      return getMultipleFilterUpdatedState(state, action);
    default:
      return state;
  }
};
