import React, { useState } from 'react'
import { FavoriteProvider } from './FavoriteContext'
import { setLocalStorage, getLocalStorage } from './../../utils/localStorage';
import { LOCALSTORAGE_FAVORITE_KEY } from './../../constants/index';


function FavoriteContainer({ children }) {
  const [favorites, setFavorites] = useState(JSON.parse(getLocalStorage(LOCALSTORAGE_FAVORITE_KEY) || '[]'));

  const update = (values = []) => {
    setLocalStorage(LOCALSTORAGE_FAVORITE_KEY, JSON.stringify(values));
    setFavorites(values)
  }
  const addToFavorite = (itemUniqueKey) => update([...favorites, itemUniqueKey])
  const removeFromFavorite = (itemUniqueKey) => update(favorites.filter(x => x !== itemUniqueKey));
  const isFavorite = (itemUniqueKey) => favorites.includes(itemUniqueKey);

  return (
    <FavoriteProvider
      value={{
        favorites,
        addToFavorite,
        removeFromFavorite,
        isFavorite,
      }}
    >
      {children}
    </FavoriteProvider>
  )
}

export default FavoriteContainer