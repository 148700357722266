import { variantItems } from './variants';
import { variantItemsAR } from './variants_ar';
import { variantItemsZH } from './variants_zh';
import { fontFamilyEN, fontFamilyAR, fontFamilyZH, fontFamilyHE } from './font';
import { arabicLang, chineseLang, russianLang, hebrewLang } from './constants';

export function getVariant(selectedLang) {
  if (selectedLang === arabicLang) {
    return variantItemsAR;
  } else if (selectedLang === chineseLang ||
    selectedLang === hebrewLang) {
    return variantItemsZH;
  } else {
    return variantItems;
  }
}

export function getFontFamily(selectedLang) {
  return fontFamilyEN;
}
