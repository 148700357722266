import UIAppOther from './SiteB';
import VAD from './vad';

/**
 * Theme Handler incase of Multiple theme(for different location) eg. UIApp-main, UIAPP-other, VAD-SiteB
 * TODO : Improve naming convention
 */
const theme = ({ siteName, selectedLang }) => {
  if (!siteName) {
    return VAD(selectedLang);
  }
  switch (`${siteName.slice(-2)}`) {
    case 'vad':
      return VAD(selectedLang);
    case 'vad-other':
      return UIAppOther(selectedLang);
    default:
      return VAD(selectedLang);
  }
};

export default theme;
