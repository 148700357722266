import * as actionTypes from './actionTypes';
/**
 *  Initial Reducer
 */
const initialState = {
  disclaimerMessage: '',
  campaignStart: '',
  campaignEnd: '',
  applyCampaignValidation: '',
};

const updateDisclaimerMessage = (state, action) => {
  return { ...state, ...action.payload };
};


export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DISCLAIMER_MESSAGE:
      return updateDisclaimerMessage(state, action);
    default:
      return state;
  }
};
