import { desktopMedia, pxToRem, mobileMedia } from '../constants';
import spacing from '../spacing';
import shadows from '../shadows';
import { borders } from '../borders';
import { mixins } from '../mixins';
import { palette } from '../palette';
import { getVariant } from '../utils';
import { fontSize } from '../font';
import { borderRadius } from '../borderRadius';

const buttons = (isRtl, selectedLang) => {
  const variants = getVariant(selectedLang);
  return {
    MuiButton: {
      root: {
        boxShadow: shadows[0],
        padding: '0',
        borderRadius: borderRadius.b0,
        textAlign: 'left',
        color: 'inherit',
        textTransform: 'initial',
        ...variants.body1Bold,
        '&.btnWithBorder': {
          paddingTop: spacing(2),
          borderTop: '0',
          [desktopMedia]: {
            borderTop: borders.divider,
          },
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${palette.common.SeaPort}`,
        },
        '&:hover': {
          backgroundColor: palette.background.transparent,
        },
      },
      contained: {
        borderRadius: borderRadius.b28Rem,
        padding: '0.75rem 1.5rem',
        '& [class^="MuiButton-label"]': {
          color: mixins(isRtl).White(),
        },
        '&[class^="Mui-disabled"]': {
          backgroundColor: palette.action.disabledBackground,
          color: mixins(isRtl).White(),
        },
      },
      containedSizeSmall: {
        padding: `${pxToRem(8)}  ${pxToRem(24)}`,
      },
      containedSizeLarge: {
        padding: `${pxToRem(8)}  ${pxToRem(24)}`,
      },
      text: {
        padding: 0,
      },
      textSizeSmall: {
        padding: 0,
      },
      textPrimary: {
        color: mixins(isRtl).Black(),
        backgroundColor: palette.background.transparent,
        '&:hover': {
          backgroundColor: palette.background.transparent,
        },
        [mobileMedia]: {
          fontSize: fontSize.s16Rem,
          lineHeight: fontSize.s24Rem,
          '& [class^="MuiButton-iconSizeMedium"] > *:first-child': {
            fontSize: fontSize.s20Rem,
            '& [class^="MuiSvgIcon-root"]': {
              fontSize: fontSize.s20Rem,
            },
          },
          '& [class^="MuiButton-endIcon"]': {
            ...mixins(isRtl).marginLeft('1rem'),
          },
        },
        '& [class^="MuiButton-endIcon"]': {
          color: mixins(isRtl).White(),
          backgroundColor: mixins(isRtl).AbuDhabiOrange(),
          borderRadius: borderRadius.circle,
          padding: '0.525rem',
          [desktopMedia]: {
            padding: '0.75rem',
          },
        },
        '&[class^="MuiButton-sizeSmall"]': {
          '& [class^="MuiButton-endIcon"]': {
            padding: '0.625rem',
          },
        },
        '&[class^="Mui-disabled"]': {
          '& [class^="MuiButton-endIcon"]': {
            backgroundColor: palette.action.disabledBackground,
          },
        },
      },
      textSecondary: {
        backgroundColor: palette.background.transparent,
        '& [class^="MuiButton-label"]': {
          color: mixins(isRtl).AbuDhabiOrange(),
        },
        '&[class^="Mui-disabled"]': {
          '& [class^="MuiButton-startIcon"]': {
            color: palette.action.disabled,
          },
        },
        '&:hover': {
          '& [class^="MuiButton-startIcon"]': {
            color: mixins(isRtl).White(),
            backgroundColor: mixins(isRtl).AbuDhabiOrange(),
          },
        },
      },
      sizeSmall: {
        ...variants.body2Bold,
        '& [class^="MuiButton-endIcon"]': {
          ...mixins(isRtl).marginLR('1rem!important', '0!important'),
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: fontSize.s20Rem,
        },
        '& [class^="MuiSvgIcon-root"]': {
          fontSize: fontSize.s20Rem,
        },
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: fontSize.s24Rem,
        },
      },
      sizeLarge: {
        ...variants.body1Bold,
        '& [class^="MuiButton-endIcon"]': {
          ...mixins(isRtl).marginLR('1rem!important', '0!important'),
        },
      },
      iconSizeLarge: {
        '& > *:first-child': {
          fontSize: fontSize.s24Rem,
        },
        '& [class^="MuiSvgIcon-root"]': {
          fontSize: fontSize.s24Rem,
        },
      },
      startIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...mixins(isRtl).marginLR(pxToRem(8), pxToRem(8)),
        height: spacing(4),
        width: spacing(4),
        borderRadius: borderRadius.circle,
        padding: pxToRem(6),
        '&$iconSizeSmall': {
          ...mixins(isRtl).marginLR(spacing(1), spacing(1)),
        },
      },
      endIcon: {
        ...mixins(isRtl).marginLeft('1.75rem'),
        ...mixins(isRtl).marginRight('0'),
      },
    },
    MuiButtonBase: {
      root: {
        '&.Mui-focusVisible': {
          outline: `2px solid ${palette.common.SeaPort}`,
        },
      },
    },
  };
};

export default buttons;
