import * as actionTypes from './actionTypes';
import { defaultTemperature } from '../../../../../constants';
/**
 *  Initial LiveWeatherInfo Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
  selectedTemperature: defaultTemperature,
};
/**
 * On Get LiveWeatherInfo Start Action
 */
const getLiveWeatherInfoStart = state => {
  return { ...state, error: null, loading: true };
};

/**
 * On Get LiveWeatherInfo Success Action
 */
const getLiveWeatherInfoSuccess = (state, action) => {
  return { ...state, data: action.payload, error: null, loading: false };
};

/**
 * On Get LiveWeatherInfo Fail Action
 */
const getLiveWeatherInfoFail = (state, action) => {
  return { ...state, error: true, loading: false, message: action.payload };
};

const setTemp = (state, action) => {
  return {
    ...state,
    selectedTemperature: action.value,
  };
};

/**
 * Update LiveWeatherInfo Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIVEWEATHERINFO_START:
      return getLiveWeatherInfoStart(state);
    case actionTypes.GET_LIVEWEATHERINFO_SUCCESS:
      return getLiveWeatherInfoSuccess(state, action);
    case actionTypes.GET_LIVEWEATHERINFO_FAIL:
      return getLiveWeatherInfoFail(state, action);
    case actionTypes.SET_TEMPERATURE:
      return setTemp(state, action);
    default:
      return state;
  }
};
