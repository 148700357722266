/* eslint-disable max-len */
import React from 'react';
import { palette } from "../themes/vad/palette"

const Favorite = (props) => {
  return (
    <>
      {!props.stroked && <svg
        version="1.1"
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        width="22" height="20" viewBox="0 0 22 20" fill={props.filled ? palette.text.secondary : palette.common.DisabledFavIcon(200)} >


        <path d="M18.065 6.88209C19.3876 8.3782 19.3368 10.6745 17.9124 12.109L11.9997 18.0632L6.08757 12.109C4.61046 10.6213 4.61046 8.20692 6.08757 6.71923C7.38739 5.4101 9.74131 5.79609 11.4227 7.48952C11.7409 7.80996 12.2591 7.80996 12.5773 7.48952C14.2587 5.79609 16.6126 5.4101 17.9124 6.71923L18.065 6.88209Z" />
        <path d="M19.5335 7.38561C19.2211 6.77454 18.7695 6.24543 18.2151 5.84094C17.6606 5.43646 17.0189 5.16793 16.3416 5.05699C15.6644 4.94606 14.9705 4.99584 14.316 5.2023C13.6615 5.40877 13.0647 5.76615 12.5737 6.24563L12.0003 6.76562L11.447 6.25897C10.9566 5.77245 10.3573 5.40981 9.69882 5.20113C9.0403 4.99244 8.34153 4.94372 7.66043 5.05899C6.98094 5.1633 6.33636 5.429 5.78075 5.83382C5.22513 6.23864 4.77468 6.77078 4.46716 7.38561C4.05422 8.19236 3.90977 9.10992 4.05487 10.0045C4.19997 10.8991 4.62704 11.724 5.27381 12.3588L11.5204 18.7987C11.5825 18.8632 11.6571 18.9146 11.7396 18.9496C11.822 18.9847 11.9107 19.0027 12.0003 19.0027C12.09 19.0027 12.1786 18.9847 12.2611 18.9496C12.3436 18.9146 12.4181 18.8632 12.4803 18.7987L18.7202 12.3722C19.3692 11.736 19.7981 10.9092 19.9444 10.0122C20.0906 9.11528 19.9467 8.19505 19.5335 7.38561ZM17.7736 11.4322L12.2403 17.1321C12.176 17.1947 12.0901 17.2305 12.0003 17.2321C11.9108 17.2292 11.8254 17.1936 11.7603 17.1321L6.22046 11.4255C5.77614 10.9875 5.48198 10.4199 5.38018 9.80434C5.27837 9.18874 5.37416 8.55672 5.6538 7.99893C5.86718 7.57032 6.18004 7.19901 6.56624 6.91603C6.95245 6.63304 7.40077 6.44661 7.87376 6.3723C8.35462 6.29181 8.84777 6.32791 9.31179 6.47756C9.77581 6.6272 10.1971 6.88602 10.5404 7.23228L11.5604 8.15893C11.6828 8.26949 11.842 8.33068 12.007 8.33068C12.172 8.33068 12.3312 8.26949 12.4537 8.15893L13.4936 7.21228C13.8385 6.86911 14.2606 6.61371 14.7247 6.46755C15.1887 6.32138 15.681 6.28872 16.1603 6.3723C16.6273 6.45154 17.0689 6.64025 17.4489 6.92298C17.829 7.20571 18.1367 7.57442 18.3469 7.99893C18.6262 8.5584 18.7212 9.19196 18.6182 9.80875C18.5152 10.4255 18.2195 10.9939 17.7736 11.4322Z" />
      </svg>
      }

      {props.stroked && <svg
        version="1.1"
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        width="20" height="18" viewBox="3 4 18 16" fill={props.filled ? palette.text.secondary : palette.common.DisabledFavIcon(200)} >
        <path stroke="#FFF" strokeWidth="1.5" d="M19.5335 7.38561C19.2211 6.77454 18.7695 6.24543 18.2151 5.84094C17.6606 5.43646 17.0189 5.16793 16.3416 5.05699C15.6644 4.94606 14.9705 4.99584 14.316 5.2023C13.6615 5.40877 13.0647 5.76615 12.5737 6.24563L12.0003 6.76562L11.447 6.25896C10.9556 5.77189 10.3552 5.40897 9.69549 5.20029C9.03582 4.9916 8.3359 4.94316 7.65376 5.05899C6.97546 5.16429 6.33223 5.43046 5.77785 5.83523C5.22346 6.24 4.77404 6.77161 4.46716 7.38561C4.05422 8.19236 3.90977 9.10992 4.05487 10.0045C4.19997 10.8991 4.62704 11.724 5.27381 12.3588L11.5204 18.7987C11.5825 18.8632 11.6571 18.9146 11.7396 18.9496C11.822 18.9847 11.9107 19.0027 12.0003 19.0027C12.09 19.0027 12.1786 18.9847 12.2611 18.9496C12.3436 18.9146 12.4181 18.8632 12.4803 18.7987L18.7202 12.3722C19.3692 11.736 19.7981 10.9092 19.9444 10.0122C20.0906 9.11528 19.9467 8.19505 19.5335 7.38561Z" fill={props.filled ? "#FF8707" : palette.common.DisabledFavIcon(200)} />

      </svg>
      }
    </>
  )
}

export default Favorite;