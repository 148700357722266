import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useStyles from './style';
import { useTheme } from '@material-ui/core/styles';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import { useMediaQuery } from '@material-ui/core';

const TextRotateAnimation = forwardRef((props, ref) => {
  const { children, className, animateSettings = {}, border } = props;
  const txtLayerAnimate = useRef(null);
  const txtLayerAnimateWrapper = useRef(null);
  const borderRef = useRef(null);
  const {
    duration = '500ms',
    borderDuration = '250ms',
    borderDelay = '0.150s',
    selfTrigger = true,
    selfTriggerMobOnly = false,
    willAnimate = true,
    animateType = 'rotate',
    logMe = false,
    rotateReversed = false,
  } = animateSettings;
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.md}px)`);
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const getSkewyDeg = {
    max: {
      normal: isRTL ? '-3deg' : '3deg',
      inverse: isRTL ? '3deg' : '-3deg',
    },
    min: {
      normal: isRTL ? '-1.5deg' : '1.5deg',
      inverse: isRTL ? '1.5deg' : '-1.5deg',
    },
  }
  let { textYPos = '16px' } = animateSettings;
  if (rotateReversed && textYPos.split('-').length === 1) { textYPos = '-' + textYPos };

  const classes = useStyles();

  let textAnim, textAnimY, borderAnim;

  useImperativeHandle(ref, () => ({
    animPlay() {
      textAnim && textAnim.play();
      borderAnim && borderAnim.play();
    },
    animReverse() {
      textAnim && textAnim.reverse();
      borderAnim && borderAnim.reverse();
    },
    animYPlay() {
      textAnimY && textAnimY.play();
    },
    animYReverse() {
      textAnimY && textAnimY.reverse();
    },
  }), [textAnim]);

  useEffect(() => {
    if (selfTriggerMobOnly && isMobile) {
      selfTrigger = true;
    }
    gsap.registerPlugin(ScrollTrigger);
    setTimeout(function () {
      if (txtLayerAnimate.current && willAnimate) {
        let skewDeg = rotateReversed ? getSkewyDeg.max.inverse : getSkewyDeg.max.normal;
        // set less skew if div is more than 30% of winWidth
        if (typeof window !== 'undefined') {
          txtLayerAnimate.current && txtLayerAnimate.current.offsetWidth && window.innerWidth * 0.3 < txtLayerAnimate.current.offsetWidth && (skewDeg = rotateReversed ? getSkewyDeg.min.inverse : getSkewyDeg.min.normal);
        }
        gsap.set(txtLayerAnimate.current, {
          transformOrigin: isRTL ? 'center right' : 'center left',
        });
        if (borderRef.current) {
          gsap.set(borderRef.current, {
            transformOrigin: isRTL ? 'center right' : 'center left',
          });

          borderAnim = gsap.timeline();
          borderAnim.fromTo(
            borderRef.current,
            {
              skewY: skewDeg,
              opacity: 0,
              ease: 'power1.out',
              y: '16px',
              transformOrigin: isRTL ? 'center right' : 'center left',
              paused: true,
              duration: borderDuration || duration,
            },
            {
              skewY: '0deg',
              y: '0px',
              transformOrigin: isRTL ? 'center right' : 'center left',
              opacity: 1,
            },
            `+=${borderDelay}`
          );
          borderAnim.pause();
        }
        if (animateType.toLowerCase() === 'rotate') {
          textAnim = gsap.fromTo(
            txtLayerAnimate.current,
            {
              skewY: skewDeg,
              opacity: 0,
              ease: 'power1.out',
              y: textYPos,
              transformOrigin: isRTL ? 'center right' : 'center left',
              paused: true,
              duration,
            },
            {
              skewY: '0deg',
              y: '0px',
              transformOrigin: isRTL ? 'center right' : 'center left',
              opacity: 1,
            }
          );
          textAnim.pause();
        }
        if (animateType.toLowerCase() === 'y') {
          gsap.set(txtLayerAnimate.current, {
            opacity: 1,
          });
          textAnimY = gsap.fromTo(
            txtLayerAnimate.current,
            {
              y: '8px',
              ease: 'power1.out',
            },
            {
              y: '0px',
            }
          );
          textAnimY.pause();
        }
        if (selfTrigger) {
          let delayPlayPause;
          let haveAnimatedOnce = false;
          gsap.timeline({
            scrollTrigger: {
              trigger: txtLayerAnimateWrapper.current,
              start: 'top bottom',
              end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
              scrub: true,
              once: true,
              onUpdate: self => {
                if (self.progress > 0.15) {
                  clearTimeout(delayPlayPause);
                  delayPlayPause = setTimeout(() => {
                    textAnim && textAnim.play();
                    borderAnim && borderAnim.play();
                  }, 200);
                  haveAnimatedOnce = true;
                } else if (haveAnimatedOnce) {
                  clearTimeout(delayPlayPause);
                  delayPlayPause = setTimeout(() => {
                    textAnim && textAnim.reverse();
                    borderAnim && borderAnim.reverse();
                  }, 200);
                }
              },
            },
          });
        }
      }
    }, 100);
  }, []);

  return (
    <ConditionalWrapper
      condition={willAnimate}
      wrapper={children => (
        <div className={`${classes.scrollAnimateWrapper} ${className ? className : ''}`} ref={txtLayerAnimateWrapper}>
          <div className={classes.scrollAnimate} ref={txtLayerAnimate}>
            {children}
          </div>
          {border ? (
            <i className={classes.border} ref={borderRef} />
          ) : ''}
        </div>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
});

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default TextRotateAnimation;
