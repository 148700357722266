import * as actionTypes from './actionTypes';
/**
 *  Initial DualSlider Reducer
 */
const initialState = {
  videoSlides: [],
  firstYoutubeVideoIndex: null,
  firstmp4VideoIndex: null,
  playFirstVideo: null,
  playAllYoutubeVideos: false,
  slideStatusIndex: null,
  playFirstYoukuVideo: false,
};

/**
 * On DualSlider Start Action
 */
const updatePlayFirstYoutubeVideo = (state, action) => {
  return { ...state, firstYoutubeVideoIndex: action.payload };
};

const updatePlayFirstYoukuVideo = (state, action) => {
  return { ...state, playFirstYoukuVideo: action.payload };
};

const updatePlayAllYoutubeVideos = (state, action) => {
  return { ...state, playAllYoutubeVideos: action.payload };
};

const updateSeekToZero = (state, action) => {
  return { ...state, slideStatusIndex: action.payload };
};

/**
 * Update DualSlider Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PLAY_FIRST_YOUTUBE_VIDEO:
      return updatePlayFirstYoutubeVideo(state, action);
    case actionTypes.PLAY_FIRST_YOUKU_VIDEO:
      return updatePlayFirstYoukuVideo(state, action);
    case actionTypes.PLAY_ALL_YOUTUBE_VIDEOS:
      return updatePlayAllYoutubeVideos(state, action);
    case actionTypes.SET_SEEK_TO_ZERO_OF_SLIDE:
      return updateSeekToZero(state, action);
    default:
      return state;
  }
};
