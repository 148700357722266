import { pxToRem } from './constants';

const baseFont = ' Arial, sans-serif';

export const fontFamilyEN = {
  primaryLight: ['BasicAbuDhabi-Light', baseFont].join(','),
  primaryRegular: ['BasicAbuDhabi-Regular', baseFont].join(','),
  primaryMedium: ['BasicAbuDhabi-Medium', baseFont].join(','),
  primaryBold: ['BasicAbuDhabi-Bold', baseFont].join(','),
  primaryBlack: ['BasicAbuDhabi-Black', baseFont].join(','),
  secondaryRegular: ['BasicAbuDhabi-Regular', baseFont].join(','),
  secondaryBold: ['BasicAbuDhabi-Bold', baseFont].join(','),
};

export const fontFamilyAR = {
  primaryLight: ['BasisGrotesqueArabic-Light', baseFont].join(','),
  primaryRegular: ['BasisGrotesqueArabic-Regular', baseFont].join(','),
  primaryBold: ['BasisGrotesqueArabic-Bold', baseFont].join(','),
  primaryBlack: ['BasisGrotesqueArabic-Black', baseFont].join(','),
};

export const fontFamilyHE = {
  primaryLight: ['Heebo-Light', baseFont].join(','),
  primaryRegular: ['Heebo-Regular', baseFont].join(','),
  primaryBold: ['Heebo-Bold', baseFont].join(','),
};

export const fontFamilyZH = {
  primaryLight: ['NotoSansSc-Light', baseFont].join(','),
  primaryRegular: ['NotoSansSc-Regular', baseFont].join(','),
  primaryBold: ['NotoSansSc-Bold', baseFont].join(','),
  primaryBlack: ['NotoSansSc-Black', baseFont].join(','),
  secondaryRegular: ['NotoSerifSc-Regular', baseFont].join(','),
  secondarySemiBold: ['NotoSerifSc-SemiBold', baseFont].join(','),
  secondaryBold: ['NotoSerifSc-Bold', baseFont].join(','),
  secondaryBlack: ['NotoSerifSc-Black', baseFont].join(','),
};

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
};

export const fontSize = {
  s14px: '14px',
  s15px: '15px',
  s16px: '16px',
  s24px: '24px',
  s0Rem: pxToRem(0),
  s9point12Rem: pxToRem(9.12),
  s10point4Rem: pxToRem(10.4), // 0.65rem
  s11point2Rem: pxToRem(11.2), // 0.7rem
  s12point8Rem: pxToRem(12.8), // 0.8rem
  s12Rem: pxToRem(12),
  s14Rem: pxToRem(14),
  s16Rem: pxToRem(16), // 1rem
  s18Rem: pxToRem(18),
  s19Rem: pxToRem(19),
  s20Rem: pxToRem(20),
  s22Rem: pxToRem(22),
  s22point4Rem: pxToRem(22.4),
  s24Rem: pxToRem(24), // 1.5rem
  s28Rem: pxToRem(28),
  s30Rem: pxToRem(30),
  s32Rem: pxToRem(32), // 2rem
  s33point6Rem: pxToRem(33.6),
  s36Rem: pxToRem(36),
  s40Rem: pxToRem(40),
  s44Rem: pxToRem(44),
  s48Rem: pxToRem(48), // 3rem
  s56Rem: pxToRem(56), // 3.5rem
  s64Rem: pxToRem(64),
  s72Rem: pxToRem(72),
  s80Rem: pxToRem(80),
  s96Rem: pxToRem(96),
  s120Rem: pxToRem(120),
  s140Rem: pxToRem(140),
  s160Rem: pxToRem(160),
  s190Rem: pxToRem(190),
};

export const ArFontSize = {
  ...fontSize,
  s19Rem: pxToRem(19),
  s26Rem: pxToRem(26),
  s27Rem: pxToRem(27),
  s30Rem: pxToRem(30),
  s42Rem: pxToRem(42),
  s45Rem: pxToRem(45),
  s54Rem: pxToRem(54),
  s60Rem: pxToRem(60),

  s118Rem: pxToRem(118),
  s142Rem: pxToRem(142),
};
