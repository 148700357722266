/*
    This method checks for window object and process.browser
    Accept params: na
    Return: {boolean}
*/

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement &&
  process.browser
);
