import { createSelector } from 'reselect';

const getData = state => {
  return state;
};

export const filtersData = createSelector(getData, data => data.FiltersReducer);
export const selectedFiltersData = createSelector(
  getData,
  data => data.SelectedFiltersReducer
);
export const filteredData = createSelector(
  getData,
  data => data.FilteredDataReducer
);
export const clearAllClickedData = createSelector(
  getData,
  data => data.ClearAllDataReducer
);
