import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Typography from '../../../components/atoms/Typography';
import CustomLink from '../../../components/atoms/Link';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import {
  setSessionStorage,
  getSessionStorage,
} from '../../../../utils/sessionStorage';
import styles from './style';
import { canUseDOM } from '../../../utils/canUseDOM';
import Picture from '../Picture';
import Alert from '../Alert';
import { COMPONENT_NAME } from './locators';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import NoSsr from '@material-ui/core/NoSsr';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import { createDynamicLabels } from '../../../../utils/analytics';

var isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
};

const DownloadWidget = props => {
  React.useEffect(() => {
    if (
      isActive &&
      isActive.value &&
      !showDownloadWidget &&
      canUseDOM &&
      (isMobile.Android() || isMobile.iOS())
    ) {
      canUseDOM && setSessionStorage('downloadWidgetBar', 1);
    }
  }, []);
  const fields =
    props.sitecoreContext &&
    props.sitecoreContext.DownloadApp &&
    props.sitecoreContext.DownloadApp.sitecore &&
    props.sitecoreContext.DownloadApp.sitecore.route &&
    props.sitecoreContext.DownloadApp.sitecore.route.fields;

  if (isEmpty(fields)) {
    return null;
  }
  const pageName = getPageName(props);
  const componentName = getComponentName(props);

  const {
    // appHeader,
    // appSubHeader,
    appLogo,
    iosLink,
    isActive,
    playStoreLink,
  } = fields;

  const classes = styles();
  const handleClose = () => {
    setSessionStorage('downloadWidgetBar', 0);
    setSessionStorage('downloadWidget', true);
  };
  const showDownloadWidget =
    canUseDOM && Boolean(getSessionStorage('downloadWidget'));

  if (canUseDOM && !isMobile.Android() && !isMobile.iOS()) {
    return null;
  }

  const link =
    canUseDOM &&
    ((isMobile.Android() && playStoreLink) || (isMobile.iOS() && iosLink));
  const appLogoImg = (
    <Picture
      media={{
        image: appLogo,
        renditionNeeded: false,
        fallbackBackgroundNeeded: false,
      }}
      alternateAltText={link && link.value && link.value.text}
      isParalax={false}
    />
  );

  // // if hasDownloadWidget is true that means user already close the notificaiton
  // // message. So we don't have to show it again
  return canUseDOM && isActive && isActive.value && !showDownloadWidget ? (
    <NoSsr>
      <div className={classes.root} data-locator={COMPONENT_NAME}>
        <Alert
          icon={appLogoImg}
          handleCloseAlert={handleClose}
          className={classes.root}
        >
          <CustomLink
            data-id={analyticsData.dataIds.headerNavLinkClick}
            data-link={createDynamicLabels([
              pageName,
              componentName,
              analyticsData.dataLinksText.downloadText,
            ])}
            onClick={handleClose}
            className="ctaBtn"
            link={link}
          >
            <Typography
              variant="body1"
              component={'span'}
              gutterBottom={false}
              className={classes.message}
            >
              {link && link.value && link.value.text}
            </Typography>
          </CustomLink>
        </Alert>
      </div>
    </NoSsr>
  ) : null;
};

export default withErrorBoundary(DownloadWidget);
