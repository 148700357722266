import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

/*eslint-disable */
export const FilterIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_259_58488)">
      <path d="M16 10.5C17.6569 10.5 19 9.15685 19 7.5C19 5.84315 17.6569 4.5 16 4.5C14.3431 4.5 13 5.84315 13 7.5C13 9.15685 14.3431 10.5 16 10.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 7.5H13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 7.5L21 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 19.5C10.6569 19.5 12 18.1569 12 16.5C12 14.8431 10.6569 13.5 9 13.5C7.34315 13.5 6 14.8431 6 16.5C6 18.1569 7.34315 19.5 9 19.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 16.5H6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 16.5L21 16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_259_58488">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'FilterIcon',
  '0 0 24 24'
);