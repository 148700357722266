import * as actionTypes from './actionTypes';

export const getLiveWeatherInfoStart = (language) => {
  return {
    type: actionTypes.GET_LIVEWEATHERINFO_START,
    language,
  };
};

export const getLiveWeatherInfoSuccess = payload => {
  return {
    type: actionTypes.GET_LIVEWEATHERINFO_SUCCESS,
    payload,
  };
};

export const getLiveWeatherInfoFail = payload => {
  return {
    type: actionTypes.GET_LIVEWEATHERINFO_FAIL,
    payload,
  };
};

export const setTemperature = value => {
  return {
    type: actionTypes.SET_TEMPERATURE,
    value,
  };
}