import * as actionTypes from './actionTypes';

// Other branches actions
export const getOtherBranchesStart = requestData => {
  return {
    type: actionTypes.GET_OTHER_BRANCHES_START,
    requestData,
  };
};

export const getOtherBranchesSuccess = payload => {
  return {
    type: actionTypes.GET_OTHER_BRANCHES_SUCCESS,
    payload,
  };
};

export const getOtherBranchesFail = payload => {
  return {
    type: actionTypes.GET_OTHER_BRANCHES_FAIL,
    payload,
  };
};

// More Like This actions
export const getMoreLikeThisStart = requestData => {
  return {
    type: actionTypes.GET_MORE_LIKE_THIS_START,
    requestData,
  };
};

export const getMoreLikeThisSuccess = payload => {
  return {
    type: actionTypes.GET_MORE_LIKE_THIS_SUCCESS,
    payload,
  };
};

export const getMoreLikeThisFail = payload => {
  return {
    type: actionTypes.GET_MORE_LIKE_THIS_FAIL,
    payload,
  };
};
