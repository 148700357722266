import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import capitalize from '@material-ui/core/utils/capitalize';
import { styles } from './style';
import TextRotateAnimation from '../TextRotateAnimation';

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h51: 'span',
  h5Bold: 'h5',
  subTitle: 'h6',
  subTitleBold: 'h6',
  labelLight: 'h6',
  labelBold: 'h6',
  labelRegular: 'h6',
  labelBlack: 'h6',
  labelBlack2: 'span',
  cardTitle1: 'h5',
  cardTitle2: 'h5',
  body1: 'p',
  body1Regular: 'p',
  body1Bold: 'p',
  body1Bold22: 'p',
  body1a: 'p',
  body2: 'p',
  body2Light: 'p',
  body2Bold: 'p',
  body3: 'p',
  body3Bold: 'p',
  body4Bold: 'p',
  body4: 'p',
  body5: 'p',
};


const Typography = forwardRef(function Typography(props, ref) {
  const {
    align = 'inherit',
    classes,
    className,
    classNameChild,
    color = 'initial',
    component,
    display = 'initial',
    gutterBottom = false,
    noWrap = false,
    paragraph = false,
    variant = 'body1',
    variantMapping = defaultVariantMapping,
    wordBreak = false,
    border = '',
    animateSettings = {},
    ...other
  } = props;

  if (
    (
      component === 'h1' ||
      component === 'h2' ||
      component === 'h4' ||
      component === 'h5' ||
      component === 'h6'
    ) &&
    !(animateSettings.hasOwnProperty('willAnimate'))
  ) {
    animateSettings.willAnimate = true;
  }
  let {
    selfTriggerMobOnly = false,
    selfTrigger = true,
    willAnimate = false,
  } = animateSettings;
  animateSettings.selfTrigger = selfTrigger;
  animateSettings.willAnimate = willAnimate;
  animateSettings.selfTriggerMobOnly = selfTriggerMobOnly;

  const Component =
    component ||
    (paragraph
      ? 'p'
      : variantMapping[variant] || defaultVariantMapping[variant]) ||
    'span';

  const animRef = useRef(null);
  const mainElementRef = useRef(null);
  useImperativeHandle(ref, () => ({
    ref: mainElementRef,
    animPlay() {
      animRef.current.animPlay();
    },
    animReverse() {
      animRef.current.animReverse();
    },
    animYPlay() {
      animRef.current.animYPlay();
    },
    animYReverse() {
      animRef.current.animYReverse();
    },
  }));

  return (
    <TextRotateAnimation
      animateSettings={animateSettings}
      className={clsx(
        classes.root,
        {
          [classes[variant]]: variant !== 'inherit',
          [classes[`text-${color}`]]: color !== 'initial',
          [classes.noWrap]: noWrap,
          [classes.gutterBottom]: gutterBottom,
          [classes.paragraph]: paragraph,
          [classes[`align${capitalize(align)}`]]: align !== 'inherit',
          [classes[`display${capitalize(display)}`]]: display !== 'initial',
          [classes.wordBreak]: wordBreak,
        },
        className,
      )}
      ref={animRef}
      border={border}>
      <Component
        className={willAnimate ? classNameChild : clsx(
          classes.root,
          {
            [classes[variant]]: variant !== 'inherit',
            [classes[`text-${color}`]]: color !== 'initial',
            [classes.noWrap]: noWrap,
            [classes.gutterBottom]: gutterBottom,
            [classes.paragraph]: paragraph,
            [classes[`align${capitalize(align)}`]]: align !== 'inherit',
            [classes[`display${capitalize(display)}`]]: display !== 'initial',
            [classes.wordBreak]: wordBreak,
          },
          className,
        )}
        ref={mainElementRef}
        {...other}
      />
    </TextRotateAnimation>
  );
});

Typography.propTypes = {
  /**
   * Set the text-align on the component.
   */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    'initial',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it maps the variant to a good default headline component.
   */
  component: PropTypes.elementType,
  /**
   * Controls the display type
   */
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  /**
   * If `true`, the text will have a bottom margin.
   */
  gutterBottom: PropTypes.bool,
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   */
  noWrap: PropTypes.bool,
  /**
   * If `true`, the text will have a bottom margin.
   */
  paragraph: PropTypes.bool,
  /**
   * if `true`, text will apply word-break: break-word
   */
  wordBreak: PropTypes.bool,
  /**
   * Applies the theme typography styles.
   */
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h5Bold',
    'h51',
    'h52',
    'h53Bold',
    'subTitle',
    'subTitleBold',
    'labelLight',
    'labelBold',
    'labelRegular',
    'labelBlack',
    'labelBlack2',
    'cardTitle1',
    'cardTitle2',
    'body1',
    'body1Regular',
    'body1Bold',
    'body1Bold22',
    'body1a',
    'body2',
    'body2Light',
    'body2Bold',
    'body3',
    'body3Bold',
    'body4Bold',
    'body4',
    'body5',
  ]),
  /**
   * We are empirically mapping the variant prop to a range of different DOM element types.
   * For instance, subTitle to `<h6>`.
   * If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` prop.
   */
  variantMapping: PropTypes.object,
};

export default withStyles(styles, { name: 'MuiTypography' })(Typography);
