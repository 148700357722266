/* eslint-disable max-len */
import React from 'react';

const SuperGraphics = (props) => {
  // const elementType = props.letter || 'B';
  return (<>
    {
      props.isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="276"
          height="778"
          viewBox="0 0 276 778"
          fill="none">
          <path opacity="0.1" d="M446.567 178.277C334.904 178.277 267.365 239.476 244.758 267.874V-42.4414C244.758 -42.4414 239.59 -42.4415 239.55 -42.4011C159.012 25.7858 46.4606 61.2528 0.640625 74.3408V79.5114C0.802105 79.875 0.963565 80.2385 1.12505 80.602C40.1628 159.17 46.299 248.605 45.6934 334.93C45.209 408.57 45.0475 482.129 45.0879 555.85C45.0879 573.463 45.1283 835.788 45.1283 835.788C87.7994 835.788 166.198 824.922 244.798 766.147C266.638 783.558 322.672 845.766 441.279 845.766C554.274 845.766 717.773 746.515 717.773 495.944C717.773 264.804 579.506 178.277 446.567 178.277ZM493.397 661.524C478.137 718.037 446.85 774.145 383.913 782.628C378.383 783.396 372.811 783.719 367.281 783.719C318.998 783.719 272.896 756.048 244.758 717.39V337.111V322.286V307.623C273.017 270.217 318.473 245.333 363.324 245.333C363.607 245.333 363.89 245.333 364.172 245.333C457.75 245.98 492.629 347.21 504.458 424.526C516.367 502.367 513.945 585.379 493.397 661.524Z" fill="black" />
        </svg>
      ) : (
        <svg
          xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
          width="1098"
          height="895"
          viewBox="0 0 1098 895"
          fill="none">
          <path opacity="0.1" d="M774.165 383.186C580.308 383.186 463.055 489.431 423.807 538.732V2.48591e-09C423.807 2.48591e-09 414.836 -0.00014553 414.766 0.0699836C274.946 118.448 79.5471 180.022 0 202.743V211.72C0.280342 212.351 0.56065 212.982 0.840993 213.613C68.6137 350.015 79.2666 505.281 78.2153 655.147C77.3743 782.992 77.094 910.698 77.1641 1038.68C77.1641 1069.26 77.2342 1524.68 77.2342 1524.68C151.315 1524.68 287.421 1505.81 423.877 1403.78C461.794 1434 559.072 1542 764.984 1542C961.153 1542 1245 1369.69 1245 934.682C1245 533.402 1004.96 383.186 774.165 383.186ZM855.464 1222.14C828.972 1320.25 774.656 1417.66 665.392 1432.39C655.791 1433.72 646.119 1434.28 636.517 1434.28C552.695 1434.28 472.657 1386.24 423.807 1319.13V658.934V633.196V607.739C472.867 542.8 551.783 499.6 629.648 499.6C630.139 499.6 630.63 499.6 631.12 499.6C793.579 500.722 854.133 676.466 874.668 810.693C895.343 945.832 891.138 1089.95 855.464 1222.14Z" fill="black" />
        </svg>
      )
    }
  </>)
}

export default SuperGraphics;