import get from 'lodash/get';

export const getComponentName = props => {
  const componentName = get(props, 'rendering.componentName', '');
  return componentName;
};

export const getPageName = props => {
  const pageName = get(props, 'sitecoreContext.route.name', '').replace(
    /\-/g,
    ' '
  );
  return pageName;
};
