/* eslint-disable quotes */
const specialCharacterArray = [
  { char: `&`, replaceWith: 'and' },
  { char: `'`, replaceWith: `\'` },
  { char: `"`, replaceWith: `\"` },
]

export const getStringToShowInCalendar = (str) => {
  let finalString = '';
  if (str) {
    finalString = str.replace(/(\r\n|\n|\r)/gm, " ");

    specialCharacterArray.forEach(item => {
      const regularExp = new RegExp(item.char, 'g');
      if (finalString && finalString.indexOf(item.char) >= 0) {
        finalString = finalString.replace(regularExp, item.replaceWith);
      }
    });

    return finalString;
  }
  return '';
}