import React, { useEffect } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import { Route, Switch } from 'react-router-dom';
import componentFactory from './temp/componentFactory';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import store, { sagaMiddleware } from './store';
import rootSaga from './store/rootSaga';
import { dataFetcher } from './dataFetcher';
import { makeStyles } from '@material-ui/core/styles';
import { canUseDOM } from './global/utils/canUseDOM';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import theme from './styles/themes';
import FavoriteContainer from './context/favorite/FavoriteContainer';
// add css global file
import './style.css';

sagaMiddleware.run(rootSaga);

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

const styles = theme => {
  return theme.overrides;
};

const get_Vh_innerHeight = () => {
  let innerHeight = 0;
  if (
    typeof window.Android !== "undefined" &&
    window.Android &&
    window.Android.getWinHeight
  ) {
    let scale = window.Android.getPixelDensity();
    if (scale < 1 || scale > 3) {
      scale = 1;
    }
    innerHeight = (window.Android.getWinHeight() / scale);
    window.androidHeight = (window.Android.getWinHeight() / scale)
  } else {
    innerHeight = Math.min(window.innerHeight, window.visualViewport.height);
  }
  let vh = innerHeight * 0.01;
  return { vh, innerHeight };
};

export const setCSSVar = () => {
  let { vh } = get_Vh_innerHeight();
  let vw = window.innerWidth * 0.01;
  let winWidth = window.innerWidth;

  // check if window.location.pathname is '/' or has only 2 letters after / i.e /en or /zh/
  if (!(window.location.pathname === '/' || window.location.pathname.length <= 4)) {
    document.documentElement.style.setProperty('--transparentHeaderHeight', '0px');
    if (winWidth < 1024 && window.location.pathname.includes("explore-map")) {
      document.documentElement.style.setProperty('--headerHeight', '0px');
    }
    else if (winWidth > 1439) {
      document.documentElement.style.setProperty('--headerHeight', '128px');
    } else if (winWidth > 1023 && winWidth < 1440) {
      document.documentElement.style.setProperty('--headerHeight', '102.39px');
    } else if (winWidth > 1023) {
      document.documentElement.style.setProperty('--headerHeight', '98px');
    } else {
      document.documentElement.style.setProperty('--headerHeight', '84px');
    }
  } else {
    document.documentElement.style.setProperty('--headerHeight', '0px');
    if (winWidth > 1439) {
      document.documentElement.style.setProperty('--transparentHeaderHeight', '128px');
    } else if (winWidth > 1023 && winWidth < 1440) {
      document.documentElement.style.setProperty('--transparentHeaderHeight', '102.39px');
    } else if (winWidth > 1023) {
      document.documentElement.style.setProperty('--transparentHeaderHeight', '98px');
    } else {
      document.documentElement.style.setProperty('--transparentHeaderHeight', '80px');
    }
  }
  document.documentElement.style.setProperty('--vh', vh + 'px');
  document.documentElement.style.setProperty('--vw', vw + 'px');
  if (!document.documentElement.style.getPropertyValue('--notificationBar')) {
    document.documentElement.style.setProperty('--notificationBar', '0px');
  }
  if (window.innerWidth !== window.previousWidth) {
    let { vh } = get_Vh_innerHeight();
    window.previousWidth = window.innerWidth;
    document.documentElement.style.setProperty('--vh-static', vh + 'px');
  }
}

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, pageId, appInsights, appConfig }) => {
  makeStyles(styles)();
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    if (canUseDOM && window) {
      const resetScrollTrigger = () => {
        setTimeout(function () {
          ScrollTrigger.refresh();
        }, 150);
      }

      const onResizeAndLoad = () => {
        let { vh, innerHeight } = get_Vh_innerHeight();
        if (window.innerWidth !== window.previousWidth) {
          window.previousWidth = window.innerWidth;
          document.documentElement.style.setProperty('--vh-static', vh + 'px');
        }

        // Adding classes for static height adjustments using classes instead of media query.
        let maxHeightValues = [1067, 746, 736, 705, 670, 645, 636, 600, 560, 557, 500];
        let maxHeight = maxHeightValues.filter(value => value >= innerHeight);
        maxHeight.forEach(value => {
          document.documentElement.classList.add(`max-height-${value}`);
        });

        // remove max-height value which are no longer required
        maxHeightValues.forEach(value => {
          if (!maxHeight.includes(value))
            document.documentElement.classList.remove(`max-height-${value}`);
        });

        setCSSVar();
        resetScrollTrigger();
      };

      const onDomReady = () => {
        onResizeAndLoad();
      };

      onDomReady();
      // trigger onResizeAndLoad on resize or orientation change
      window.addEventListener('resize', onResizeAndLoad, false);

      // Observer DOM height changes to trigger scrollTrigger refresh
      const resizeObserver = new ResizeObserver(() =>
        resetScrollTrigger()
      );
      resizeObserver.observe(document.body);
    }
  }, []);

  const createPath = pathName => {
    if (pathName.indexOf('/') > -1) {
      let pathItem = pathName.split('/');
      var pathLength = pathItem.length;
      if (pathLength > 2) {
        if (pathItem[2].length > 0) {
          //path  -> /en-gb/where-to-go
          pathItem.splice(1, 1);
        } else {
          //path  -> /en-gb/
          pathItem[1] = 'Home';
          pathItem.splice(2, 1);
        }
      } else {
        //  path ->/en
        pathItem[pathLength - 1] = 'Home';
      }
      return pathItem.join('/');
    } else {
      //path  -> 'not set'
      return pathName;
    }
  };

  //pushing the dataLayer values with correct path
  React.useEffect(() => {
    if (path && canUseDOM && window.dataLayer && window.dataLayer.length) {
      window.dataLayer[0].pageName = createPath(path);
      window.dataLayer[0].previousPageName = createPath(
        window.dataLayer[0].previousPageName
      );
    }
  }, [path]);

  // for adding Sitecore Page Level Analytics
  React.useEffect(() => {
    if (pageId && path) {
      const trackingApiOptions = {
        host: window ? window.location.origin : appConfig.sitecoreApiHost,
        querystringParams: {
          sc_apikey: appConfig.sitecoreApiKey,
        },
        fetcher: dataFetcher,
      };
      trackingApi
        .trackEvent([{ pageId, url: path }], trackingApiOptions)
        .catch(error => console.log(error));
    }
  }, [pageId, path]);
  const routeRenderFunction = props => (
    <RouteHandler
      route={props}
      appInsights={appInsights}
      appConfig={appConfig}
    />
  );
  return (
    <SitecoreContext
      componentFactory={componentFactory}
      contextFactory={SitecoreContextFactory}
    >
      <FavoriteContainer>
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <Router location={path} context={{}}>
            <Switch>
              {routePatterns.map(routePattern => (
                <Route
                  key={routePattern}
                  path={routePattern}
                  render={routeRenderFunction}
                />
              ))}
            </Switch>
          </Router>
          {/* <MyFirstComponent {...data}/> */}
          {/* </PersistGate> */}
        </Provider>
      </FavoriteContainer>
    </SitecoreContext>
  );
};

export default AppRoot;
