import * as actionTypes from './actionTypes';


export const getExploreMap = payload => {
  return {
    type: actionTypes.GET_EXPLOREMAP,
    payload,
  };
};

export const getExploreMapStart = payload => {
  return {
    type: actionTypes.GET_EXPLOREMAP_START,
    payload,
  };
};

export const getExploreMapSuccess = payload => {
  return {
    type: actionTypes.GET_EXPLOREMAP_SUCCESS,
    payload,
  };
};

export const getExploreMapFail = payload => {
  return {
    type: actionTypes.GET_EXPLOREMAP_FAIL,
    payload,
  };
};

export const updateSelectedCategories = payload => {
  return {
    type: actionTypes.UPDATE_SELECTED_CATEGORIES,
    payload,
  };
};

export const updateGeoJSONData = payload => {
  return {
    type: actionTypes.UPDATE_GEOJSON_DATA,
    payload,
  };
};

export const updatePlacesInView = payload => {
  return {
    type: actionTypes.UPDATE_PLACES,
    payload,
  };
};

export const setCellReselectedStatus = payload => {
  return {
    type: actionTypes.SET_RESELECTED_STATUS,
    payload,
  };
};
export const setMapStepValue = payload => {
  return {
    type: actionTypes.SET_MAP_STEP,
    payload,
  };
};

export const handleShouldMapZoom = payload => {
  return {
    type: actionTypes.SHOULD_MAP_ZOOM,
    payload,
  };
};

export const setIsMapPanning = payload => {
  return {
    type: actionTypes.SET_MAP_PANNING,
    payload,
  };
};
export const setClearingAll = payload => {
  return {
    type: actionTypes.SET_CLEARING_ALL,
    payload,
  };
};
