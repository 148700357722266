import * as actionTypes from './actionTypes';

export const getEventListStart = (language, isEditor) => {
  return {
    type: actionTypes.GET_EVENT_LIST_START,
    language,
    isEditor,
  };
};

export const getEventListSuccess = payload => {
  return {
    type: actionTypes.GET_EVENT_LIST_SUCCESS,
    payload,
  };
};

export const getEventListFail = payload => {
  return {
    type: actionTypes.GET_EVENT_LIST_FAIL,
    payload,
  };
};
