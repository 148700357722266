export const analyticsData = {
  footerInteraction: {
    subscribeEmail: {
      event: 'Event_Subscribe',
      category: 'Footer Interaction',
      action: 'Subscribe',
      label: '',
    },
    dctLogoClick: {
      event: 'Event_LogoClick',
      category: 'Footer Interaction',
      action: 'Icon Click',
      label: 'dct logo',
    },
  },
  headerInteraction: {
    searchClick: {
      event: 'Event_Search',
      category: 'Header Interaction',
      action: 'Search',
      label: '',
    },
    dctLogoClick: {
      event: 'Event_LogoClick',
      category: 'Header Interaction',
      action: 'Icon Click',
      label: 'dct logo',
    },
    toggleLanguage: {
      event: 'Event_LanguageToggle',
      category: 'Header Interaction',
      action: 'Select Language',
      label: 'en|ar',
    },
    videoPlayClick: {
      event: 'Event_VideoPlay',
      category: 'Header Interaction',
      action: 'Video Play',
      label: 'Complete Ad Experience - Golf in AD',
    },
  },
  pageBodyInteraction: {
    addToCalendar: {
      event: 'Event_AddToCalendar',
      category: 'Page Body Interaction',
      action: 'Icon Click',
      label: 'AddToCalendar_{{Event Name}}',
    },
    backToTopClick: {
      event: 'Event_BackToTop',
      category: 'Footer Interaction',
      action: 'Button Click',
      label: 'Scroll Button',
    },
    dctLogoClick: {
      event: 'Event_LogoClick',
      category: 'Page Body Interaction',
      action: 'Icon Click',
      label: 'dct logo',
    },
    contactFormsClick: {
      event: 'Event_SendFeedback',
      category: 'Page Body Interaction',
      action: 'Send Feedback',
      label: '',
    },
    arrowClick: {
      event: 'Event_ArrowClick',
      category: 'Page Body Interaction',
      action: 'Link Clicks',
      label: '',
    },
    tileClick: {
      event: 'Event_TileClick',
      category: 'Page Body Interaction',
      action: 'Tile Click',
      label: '',
    },
    favoriteNavTileClick: {
      event: 'Event_Favorite_Navigation_Tile_Click',
      category: 'Page Body Interaction',
      action: 'Favorite Nav Tile Click',
      label: '',
    },
    addItemToFavorite: {
      event: 'Event_Add_Item_Favorite_Click',
      category: 'Page Body Interaction',
      action: 'Add item to Favorite Click',
      label: '',
    },
    removeItemToFavorite: {
      event: 'Event_Remove_Item_Favorite_Click',
      category: 'Page Body Interaction',
      action: 'Remove item to Favorite Click',
      label: '',
    },
    findDealClicks: {
      event: 'Event_FindDeals',
      category: 'Page Body Interaction',
      action: '',
      label: '',
    },
    ticketBookingForm: {
      event: 'Event_TicketBookingForm',
      category: 'Page Body Interaction',
      action: 'Ticket Booking Form Submit',
      label: '',
    },
    filterBtnClick: {
      event: 'Event_FilterButton',
      category: 'Page Body Interaction',
      action: 'Filter',
      label: '',
    },
    filterMobileBtnClick: {
      event: 'Event_FilterButton',
      category: 'Page Body Interaction',
      action: 'Filter-Mobile',
      label: '',
    },
    seeAllPhotosBtnClick: {
      event: 'Event_SeeAllPhotos',
      category: 'Page Body Interaction',
      action: 'See All Photos',
      label: '',
    },
    galleryImageClick: {
      event: 'Event_GalleryClick',
      category: 'Page Body Interaction',
      action: 'Image Click',
      label: 'Complete Ad Experience - Golf in AD',
    },
    galleryVideoClick: {
      event: 'Event_GalleryClick',
      category: 'Page Body Interaction',
      action: 'Video Click',
      label: 'Complete Ad Experience - Golf in AD',
    },
    videoPlayClick: {
      event: 'Event_VideoPlay',
      category: 'Page Body Interaction',
      action: 'Video Play',
      label: 'Complete Ad Experience - Golf in AD',
    },
    swipeArrowClick: {
      event: 'Event_Swipe',
      category: 'Page Body Interaction',
      action: 'Swipe',
      label: 'Serenity Of AD Desert',
    },
    loadMoreBtnClick: {
      event: 'Event_LoadMore',
      category: 'Page Body Interaction',
      action: 'Load More',
      label: 'Hottest Spots in AD - Load More',
    },
    backToHomeLinkClick: {
      event: 'Event_Oops-Homepage',
      category: 'Page Body Interaction',
      action: 'Back To Homepage',
      label: 'Louvre Oops Page',
    },
    thingsToDoOOpsLink: {
      event: 'Event_Oops-Travel',
      category: 'Page Body Interaction',
      action: 'Things To Do',
      label: 'Louvre Oops Page ',
    },
    readMoreBtnClick: {
      event: 'Event_ReadMore',
      category: 'Page Body Interaction',
      action: 'Read More Click',
      label: 'Yas Links Abu Dhabi',
    },
    visitWebsiteClick: {
      event: 'Event_VisitWebsite',
      category: 'Page Body Interaction',
      action: 'Visit Website',
      label: '',
    },
    primaryCTAClick: {
      event: 'Event_PrimaryCTA',
      category: 'Page Body Interaction',
      action: '',
      label: '',
    },
    getDirectionsLinkClick: {
      event: 'Event_GetDirections',
      category: 'Page Body Interaction',
      action: 'Get Directions',
      label: '',
    },
    mapClick: {
      event: 'Event_MapClick',
      category: 'Page Body Interaction',
      action: 'Map Click',
      label: '',
    },
    viewItineraryMapClick: {
      event: 'Event_ViewItineraryMap',
      category: 'Page Body Interaction',
      action: 'View Itinerary Map',
      label: '',
    },
    miniNavClick: {
      event: 'Event_MiniNavClick',
      category: 'Page Body Interaction',
      action: 'Button Click',
      label: '',
    },
    rfpFormSubmit: {
      event: 'Event_RequestForProposal',
      category: 'Page Body Interaction',
      action: 'Button Click',
      label: '',
      label2: '',
    },
    temperatureToggle: {
      event: 'Event_TemparatureToggle',
      category: 'Page Body Interaction',
      action: 'Icon Click',
      label: 'C | F',
    },
    subscribeCompetition: {
      event: 'Event_Subscribe',
      category: 'Page Body Interaction',
      action: 'Subscribe',
      label: '',
    },
    dualHeroSwitchSlideClick: {
      event: 'Event_Dual_Hero_Switch_Slide_Click',
      category: 'Page Body Interaction',
      action: 'Swipe',
      label: 'Serenity Of AD Desert',
    },
    dualHeroSwipeInnerSlideClick: {
      event: 'Event_Dual_Hero_Swipe_Inner_Slide_Click',
      category: 'Page Body Interaction',
      action: 'Link Clicks',
      label: '',
    },
  },
  dataIds: {
    headerNavLinkClick: 'header_nav_linkclick',
    footerNavLinkClick: 'footer_nav_linkclick',
    footerNavAppclick: 'footer_nav_appclick',
    footerNavIconclick: 'footer_nav_iconclick',
    topNavLinkClick: 'top_nav_linkclick',
    pageBodyNavLinkClick: 'pagebody_nav_linkclick',
    pageBodyNavAppClick: 'pagebody_nav_appclick',
  },
  dataLinksText: {
    quickLinks: 'QuickLinks',
    downloadapp: 'Download App',
    followus: 'Followus',
    contactByPhone: 'Contact Us - Phone',
    contactByEmail: 'Contact Us - Email',
    downloadText: 'Download',
    siteMapText: 'SiteMap',
    breadCrumbLink: 'BreadCrumb',
    hotelFormText: 'Hotel',
    flightFormText: 'Flight',
    socialShare: 'Social-Share',
    findDeals: 'Find Deals',
    checkedText: 'Checked',
    uncheckedText: 'Unchecked',
    outlook: 'Outlook',
    gmail: 'Gmail',
    addToCalendar: 'AddToCalendar',
    temperatureCelsius: 'C',
    temperatureFahrenheit: 'F',
  },
};
