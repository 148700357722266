import getAppConfig from './global/utils/getAppConfig';
import { canUseDOM } from './global/utils/canUseDOM';

const config = canUseDOM && getAppConfig()
export const addLoggerScript = () => {
  const appInsights =
    window.appInsights ||
    (function (_config) {
      /**
       * @param {string} fn
       * @return {undefined}
       */
      function $(fn) {
        /**
         * @return {undefined}
         */
        params[fn] = function () {
          /** @type {!Arguments} */
          const original_arguments = arguments;
          params.queue.push(() => {
            params[fn](...original_arguments);
          });
        };
      }
      var params = {
        config: _config,
      };
      /** @type {!HTMLDocument} */
      const d = document;
      /** @type {!Window} */
      const result = window;
      setTimeout(() => {
        /** @type {!Element} */
        const liveReload = d.createElement('script');
        liveReload.src = _config.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js';
        liveReload.async = true;
        d.getElementsByTagName('script')[0].parentNode.appendChild(liveReload);
      });
      try {
        /** @type {string} */
        params.cookie = d.cookie;
      } catch (a) { }
      /** @type {!Array} */
      params.queue = [];
      /** @type {!Array} */
      let callback = ['Event', 'Exception', 'Metric', 'PageView', 'Trace', 'Dependency'];
      for (; callback.length;) {
        $(`track${callback.pop()}`);
      }
      if (
        ($('setAuthenticatedUserContext'),
          $('clearAuthenticatedUserContext'),
          $('startTrackEvent'),
          $('stopTrackEvent'),
          $('startTrackPage'),
          $('stopTrackPage'),
          $('flush'),
          !_config.disableExceptionTracking)
      ) {
        /** @type {string} */
        callback = 'onerror';
        $(`_${callback}`);
        const cb = result[callback];
        /**
         * @param {?} data
         * @param {?} res
         * @param {?} status
         * @param {?} funcIns
         * @param {?} text
         * @return {?}
         */
        result[callback] = function (data, res, status, funcIns, text) {
          const undefined = cb && cb(data, res, status, funcIns, text);
          return (
            undefined !== true && params[`_${callback}`](data, res, status, funcIns, text),
            undefined
          );
        };
      }
      return params;
    }({
      instrumentationKey: `${config.appInsightsInstrumentationKey}`,
      loggingLevelConsole: 2,
      loggingLevelTelemetry: 2,
    }));
  window.appInsights = appInsights;
  appInsights.queue && appInsights.queue.length === 0 && appInsights.trackPageView();
};