import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import getAppConfig from '../../../../utils/getAppConfig';
import { languageIdList, LOCATION_ID, ISMOBILE } from '../../../../../constants/index';

const apiUrl = getAppConfig();
/*
 * Get Live Weather Info Saga
 */
export function* getLiveWeatherInfoSaga({ language }) {
  try {
    let response = yield fetchService({
      url: `${apiUrl.LIVE_WEATHER_API}/${LOCATION_ID}/${languageIdList[language]}/${ISMOBILE}`,
      method: 'GET',
    });
    if (response && response.status === 200) {
      if (response.data.location) {
        yield put(
          actions.getLiveWeatherInfoSuccess({
            results: response,
          })
        );
      } else {
        yield put(actions.getLiveWeatherInfoFail(response));
      }
    } else {
      yield put(actions.getLiveWeatherInfoFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getLiveWeatherInfoFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchLiveWeatherInfo() {
  yield all([
    takeLatest(actionTypes.GET_LIVEWEATHERINFO_START, getLiveWeatherInfoSaga),
  ]);
}
