import defaultConfig from '../../temp/config';
import { canUseDOM } from './canUseDOM';

const appConfig = () => {
  let config = canUseDOM && document.getElementById('__APP_CONFIG__') || '';
  if (config && config.innerHTML) {
    config = JSON.parse(config.innerHTML) || 'not found';
  } else {
    config = defaultConfig; // Local mode
  }
  return { ...config };
};

export default appConfig;
