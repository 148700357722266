import { makeStyles } from '@material-ui/core/styles';
import { replaceKeysLabelText } from '../../../../utils/utility';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    '&.no-shrink': {
      marginBottom: pxToRem(16),
      minWidth: pxToRem(40),
      '& .MuiButton-endIcon': {
        width: pxToRem(40),
        height: pxToRem(40),
        padding: pxToRem(8),
        ...theme.mixins.marginLeft(16),
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&:not(.Mui-disabled, .no-shrink):hover': {
        overflow: 'hidden',
        '& .MuiButton-endIcon': {
          overflow: 'hidden',
        },
        '& .MuiButton-startIcon': {
          overflow: 'hidden',
        },
        '& .MuiIcon-root': {
          animationName: '$arrowMove',
          animationDuration: '.5s',
          animationIterationCount: '1',
        },
      },
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.common.LightGrey,
    },
  },
  scrollAnimate: {
    opacity: 0,
  },
  primary: {
    fontFamily: theme.custom.fontFamily.primaryRegular,
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      lineHeight: fontSize.s32Rem,
      '&.MuiButton-sizeSmall': {
        fontFamily: theme.custom.fontFamily.primaryBold,
        lineHeight: fontSize.s24Rem,
      },
    },
    '& .MuiButton-label': {
      textDecoration: 'none',
      transition: 'text-decoration 0.3s ease-in-out',
    },

    '&:hover': {
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
    },

    '& .MuiButton-endIcon': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.AbuDhabiOrange(),
    },
    '&:hover .MuiButton-endIcon': {
      backgroundColor: theme.mixins.Black(),
    },
  },
  russianVioletColorVariant: {
    color: theme.mixins.RussianViolet(),
    '& .MuiButton-endIcon': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.White(),
      '& svg': {
        fill: theme.mixins.Black(),
      },
    },
    '&:hover': {
      '& .MuiButton-endIcon': {
        backgroundColor: props =>
          props.themeColor
            ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
            : theme.mixins.RussianViolet(),
        '& svg': {
          fill: theme.mixins.White(),
        },

      },
    },
  },
  whiteColorVariant: {
    color: theme.mixins.White(),
    '& .MuiButton-endIcon': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.White(),
      '& svg': {
        fill: theme.mixins.Black(),
      },
    },
    '&:hover': {
      '& .MuiButton-endIcon': {
        '& svg': {
          fill: theme.mixins.White(),
        },

      },
    },
  },
  whitePillContrast: {
    '&.MuiButton-contained': {
      '& .MuiButton-label': {
        color: theme.mixins.RussianViolet(),
      }
    },
    backgroundColor: theme.mixins.White(),
    '& svg': {
      fill: theme.mixins.RussianViolet(),
    },
    '&:hover': {
      '&.MuiButton-contained': {
        '& .MuiButton-label': {
          color: theme.mixins.White(),
        }
      },
      backgroundColor: theme.mixins.RussianViolet(),
      '& svg': {
        fill: theme.mixins.White(),
      },
    },
  },
  secondary: {
    [theme.breakpoints.up('lg')]: {
      lineHeight: fontSize.s32Rem,
      '&.MuiButton-sizeSmall': {
        lineHeight: fontSize.s24Rem,
      },
    },
    '& .MuiButton-label': {
      color: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.AbuDhabiOrange(),
    },
    '&:hover': {
      '& .MuiButton-startIcon': {
        backgroundColor: props =>
          props.themeColor
            ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
            : theme.mixins.AbuDhabiOrange(),
      },
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
    },
  },
  playBtn: {
    backgroundColor: props =>
      props.themeColor
        ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
        : theme.mixins.AbuDhabiOrange(),
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.mixins.Black(),
    },
    '& .playBtnSvg': {
      border: '1px solid #fff',
      borderRadius: '50%',
      width: pxToRem(29),
      height: pxToRem(29),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: pxToRem(13),
        height: pxToRem(13),
      },
    },
  },
  form: {
    backgroundColor: props =>
      props.themeColor
        ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
        : theme.mixins.AbuDhabiOrange(),
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.mixins.Black(),
    },

  },

  '@keyframes arrowMove': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: `translateX(${theme.mixins.translateX(130)}%)` },
    '51%': { transform: `translateX(${theme.mixins.translateX(-130)}%)` },
    '100%': { transform: 'translateX(0)' },
  },
  scrollAnimateWrapper: {
    backfaceVisibility: 'hidden',
    position: 'relative',
    '& + .MuiButton-endIcon': {
      opacity: 0,
    },
  },
}));

export default useStyles;
