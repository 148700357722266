import * as actionTypes from './actionTypes';

export const getSmartListStart = (payload, uid) => {
  return {
    type: actionTypes.GET_SMARTLIST_START,
    payload,
    uid,
  };
};

export const resetSmartList = () => {
  return {
    type: actionTypes.RESET_SMARTLIST,
  };
};

export const getSmartListSuccess = (payload, uid) => {
  return {
    type: actionTypes.GET_SMARTLIST_SUCCESS,
    payload,
    uid,
  };
};

export const getSmartListFail = payload => {
  return {
    type: actionTypes.GET_SMARTLIST_FAIL,
    payload,
  };
};
