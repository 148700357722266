import { all, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import { filteredData } from './selectors';
import { updateFilterData } from '../../../../../utils/utility';
import { getLocalStorage } from './../../../../../utils/localStorage';


/*
 * Get Filters data from solr API
 */

export function* getFiltersInfoSaga(action) {
  yield put(actions.getFiltersInfoStart());
  try {
    const response = yield fetchService({
      url: action.payload.url,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      const data = updateFilterData(
        response.data.data,
        action.payload.sitecoreFilters,
        action.payload.dictionary
      );
      if (data !== null && data !== 'undefined') {
        yield put(
          actions.getFiltersInfoSuccess({
            results: data,
            apiResult: response.data.data,
          })
        );
      } else {
        yield put(actions.getFiltersInfoFail(response.data));
      }
    } else {
      yield put(actions.getFiltersInfoFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getFiltersInfoFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* getFilterdInfoSaga(action) {
  const filteredDataState = yield select(filteredData);
  yield put(actions.getFilterdInfoStart());
  try {
    const response = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      const { data, success } = response.data;
      if (
        action.payload.isLoadMoreClicked &&
        data.results &&
        data.results.length > 0 &&
        filteredDataState.data.results &&
        filteredDataState.data.results.length > 0
      ) {
        data.results = [...filteredDataState.data.results, ...data.results];
      }
      if (data !== null && data !== 'undefined' && success) {
        yield put(
          actions.getFilterdInfoSuccess({
            results: data,
            payload: action.payload,
          })
        );
      } else {
        yield put(actions.getFilterdInfoFail(response.data));
      }
    } else {
      yield put(actions.getFilterdInfoFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getFilterdInfoFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* getFavoritesInNavSaga(action) {
  const filteredDataState = yield select(filteredData);
  const favData = JSON.parse(getLocalStorage('favorites') || '[]');

  let data_sort;
  let success_sort;
  let totalResults = 0;

  yield put(actions.getFavoritesInNavStart());
  try {

    // passign all ItemIDs
    action.payload.payload.itemIDs = favData;
    action.payload.payload.limit = 1000;
    const response = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: action.payload.payload,
    });
    var sortedFavItem = [];
    favData.forEach(favEl => {
      response.data.data.results.forEach(itemKey => {
        if (itemKey.itemUniqueKey == favEl) {
          sortedFavItem.push(favEl);
        }
      });
    });

    // getting sorted favourite result in heart popup passing limited ItemIDs
    var payload_sort = action.payload.payload;
    var fav_sort = sortedFavItem.reverse().slice(0, 3);
    payload_sort.itemIDs = fav_sort;
    const response_sort = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: payload_sort,
    });

    if (response_sort.status === 200 && response.status === 200) {
      data_sort = response_sort.data.data;
      success_sort = response_sort.data.success

      if (data_sort !== null && data_sort !== 'undefined' && success_sort) {
        var _result = [];
        var c = 0;
        sortedFavItem.reverse().forEach(favEl => {
          data_sort.results.forEach(resultEl => {
            if (resultEl.itemUniqueKey == favEl) {
              _result[c] = resultEl;
              c++;
            }
          });
        });
        data_sort.results = _result.reverse();
      }
      const { data, success } = response.data;
      if (data !== null && data !== 'undefined' && success) {
        totalResults = data.totalResults;
      }
      yield put(
        actions.getFavoritesInNavSuccess({
          favorites: data_sort.results,
          totalFavorites: data.totalResults,
        })
      );
    }

  } catch (error) {
    yield put(
      actions.getFavoritesInNavFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchFiltersInfo() {
  yield all([takeLatest(actionTypes.GET_FILTERSINFO_DATA, getFiltersInfoSaga)]);
}

export function* watchFilterdInfo() {
  yield all([takeLatest(actionTypes.GET_FILTERD_DATA, getFilterdInfoSaga)]);
}

export function* watchFavInNav() {
  yield all([takeLatest(actionTypes.GET_FAVORITES_IN_NAV_DATA, getFavoritesInNavSaga)]);
}
