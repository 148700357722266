import { all, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import fetchService from '../../../../../services/FetchService';
import getAppConfig from '../../../../utils/getAppConfig';

const apiUrl = getAppConfig();
/*
 * Get SmartContentList Saga
 */
export function* getSmartListSaga(action) {
  try {
    const response = yield fetchService({
      url: `${apiUrl.SMART_LIST_API}`,
      method: 'POST',
      data: action.payload,
    });

    if (response.status === 200) {
      yield put(actions.getSmartListSuccess(response.data, action.uid));
    } else {
      yield put(actions.getSmartListFail(response.data, action.uid));
    }
  } catch (error) {
    yield put(
      actions.getSmartListFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchSmartListSaga() {
  yield all([takeEvery(actionTypes.GET_SMARTLIST_START, getSmartListSaga)]);
}
