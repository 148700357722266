import React from 'react';
import PropTypes from 'prop-types';
import AlertMUI from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import DctSvgIcon from '../../../components/atoms/Svg';
import Button from '../../../components/atoms/Button';
import useStyles from './style';
import { CLOSE_ICON_TEXT } from '../../../../constants';

const Alert = ({
  icon,
  ariaLabel,
  className,
  children,
  handleCloseAlert,
  ...other
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    handleCloseAlert();
    setOpen(false);
  };

  return <div className={`${classes.root} ${className}`}>
    <Collapse in={open}>
      <AlertMUI
        icon={icon}
        variant="outlined"
        action={
          <Button
            srText={ariaLabel || CLOSE_ICON_TEXT}
            color="inherit"
            size="small"
            iconOnly
            onClick={handleClose}
          >
            <DctSvgIcon name="CloseIcon" />
          </Button>
        }
        {...other}
      >
        {children}
      </AlertMUI>
    </Collapse>
  </div>
};

Alert.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  icon: PropTypes.node,
  handleCloseAlert: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  className: '',
  ariaLabel: '',
  handleCloseAlert: () => { },
  icon: <DctSvgIcon name="InfoOutlinedIcon" />,
};

export default Alert;
