import * as actionTypes from './actionTypes';

/**
 *  Initial State searchData Reducer
 */
const initialState = {
  data: false,
};
/**
 * On Get searchData Start Action
 */
const setClearAllState = (state, action) => {
  return { ...state, data: action.payload };
};

/**
 * Update searchData Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ALL_CLICKED:
      return setClearAllState(state, action);
    default:
      return state;
  }
};
