/* eslint-disable max-len */
import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export const PlayIcon = createSvgIcon(
  <path d="M15.562 8.1L3.87.225c-.818-.562-1.87 0-1.87.9v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z">
  </path>,
  'PlayIcon',
  '0 0 18 18'
);
