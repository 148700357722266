/* eslint-disable max-len */
import React from 'react';

const SuccessIcon = (
  <svg
    width="81px"
    height="79px"
    viewBox="0 0 81 79"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon / form / confirmation@1.5x</title>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="02_DCT_Long_Page_desk"
        transform="translate(-1167.000000, -4744.000000)"
        stroke="#000000"
        strokeWidth="2"
      >
        <g id="13.-Newsletter" transform="translate(0.000000, 3296.000000)">
          <g id="SUCCESS" transform="translate(884.000000, 1128.000000)">
            <g id="ICON-AND-COPY" transform="translate(158.000000, 321.000000)">
              <g id="Group-4" transform="translate(126.000000, 0.000000)">
                <g id="icon-/-form-/-confirmation">
                  <g transform="translate(0.081297, 0.079784)">
                    <path
                      d="M47.0897297,55.8875676 L78.7027027,34.1643243 L78.7027027,76.8086486 L47.0897297,55.8875676 Z M3.33955086e-13,76.8086486 L32.4345946,55.3427027 L39.3513514,50.8108108 L46.2681081,55.3427027 L78.7027027,76.8086486 L3.33955086e-13,76.8086486 Z M3.33955086e-13,76.8086486 L3.33955086e-13,34.3805405 L31.6324324,55.8745946 L3.33955086e-13,76.8086486 Z M3.33955086e-13,34.3805405 L11.2432432,23.68 L3.33955086e-13,34.3805405 Z M11.2432432,41.8075676 L11.2432432,12.3264865 L67.4594595,12.3264865 L67.4594595,41.8075676 M25.9459459,12.3264865 L39.3513514,6.60804744e-13 L52.7567568,12.3264865 L25.9459459,12.3264865 Z M67.4594595,23.68 L78.7027027,34.1643243 L67.4594595,23.68 Z"
                      id="Stroke-1"
                    ></path>
                    <polyline
                      id="Stroke-3"
                      points="28.4188108 32.3128649 34.8101622 38.7042162 50.4274595 24.9355676"
                    ></polyline>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SuccessIcon;
