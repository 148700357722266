import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { mixins } from '../../../../styles/themes/vad/mixins';

const colorMixins = mixins();

const useStyles = makeStyles(theme => ({
  scrollAnimateWrapper: {
    backfaceVisibility: 'hidden',
    position: 'relative',
  },
  scrollAnimate: {
    display: 'inline-block',
    opacity: 0,
    '& > *': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
      fontWeight: 'inherit',
      margin: '0',
    },
  },
  border: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: colorMixins.Black(200),
  },
}));

export default useStyles;