// utils.
import {
  makeActions,
  makeQueryString,
  TYPE,
  isValidResponse,
  makeRequestObj,
  networkLog,
  makeRequestHeaders,
} from '../utils/middlewareUtils';

const isValidRequest = action =>
  typeof action === 'object' && action.type === 'SITECORE_API' && action.route;

const apiService = store => next => action => {
  // validate if the action needs to be captured by this middleware, otherwise pass to next().
  if (!isValidRequest(action)) return next(action);
  const {
    name,
    callback,
    route,
    verb,
    query,
    unAuthenticated,
    additionalHeaders = {},
    metaData = {},
    queuedAction,
  } = action;

  const queryString = query ? makeQueryString(query) : '';
  const { success, failure, error, init } = name ? makeActions(name) : TYPE;

  // get the domain for the api from the environment variable.
  const { dispatch } = store;

  try {
    new Promise(resolve => {
      resolve();
    })
      .then(() => {
        if (unAuthenticated) {
          return Promise.resolve({});
        }
        // pass additional headers along to create the request object.

        return makeRequestHeaders('application/json', additionalHeaders);
      })
      // assemble complete headers.
      .then(headers => makeRequestObj(action, headers, true))
      // make the api call.
      .then(request => {
        next({
          type: init,
          payload: request,
        });
        return fetch(`${route}${queryString}`, request);
      })
      // process the response from the api into our standard format.
      .then(response =>
        response.json().then(data => ({
          dataReceived: data,
          status: response.status,
        }))
      )
      .then(({ status, dataReceived }) => {
        // check if response is valid, or an error response has been received.
        const valid = isValidResponse(status);
        const err = valid ? '' : dataReceived;
        const type = valid ? success : failure;
        // forwarding request object for reference in POST, PUT, DELETE.
        const data = callback
          ? callback(err, dataReceived, action.data)
          : dataReceived;
        networkLog(`${verb || 'GET'} - ${type}`, status);
        if (metaData) {
          data.metaData = metaData;
        }
        return next({
          type,
          payload: data,
          error: !valid,
        });
      })
      .then(prevAction => {
        if (queuedAction) {
          dispatch(queuedAction(prevAction));
        }
      })
      .catch(err => {
        // error triggered from the api response.
        // eslint-disable-next-line no-console
        console.error('Sitecore API fetch error', err.toString());
        return next({
          type: error,
          payload: {
            message: err,
          },
          error: true,
        });
      });
  } catch (err) {
    // a failure occurred inside this middleware.
    // eslint-disable-next-line no-console
    console.error('Sitecore API middleware failure', err.toString());

    return next({
      type: failure,
      payload: err,
      error: true,
    });
  }
  return {};
};

export default apiService;
