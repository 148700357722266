import { canUseDOM } from '../global/utils/canUseDOM';

export const setSessionStorage = (key, value) => {
  if (canUseDOM) {
    if (window.sessionStorage) {
      window.sessionStorage.setItem(key, value);
    }
  }
};

export const getSessionStorage = key => {
  if (canUseDOM) {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem(key);
    }
  }
};
