import * as actionTypes from './actionTypes';
/**
 *  Initial EventList Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
};
/**
 * On Get EventList Start Action
 */
const getEventListStart = state => {
  return { ...state, error: null, loading: true };
};

/**
 * On Get CollageType1 Success Action
 */
const getEventListSuccess = (state, action) => {
  return { ...state, data: action.payload, error: null, loading: false };
};

/**
 * On Get EventList Fail Action
 */
const getEventListFail = (state, action) => {
  return { ...state, error: true, loading: false, message: action.payload };
};

/**
 * Update EventList Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENT_LIST_START:
      return getEventListStart(state, action);
    case actionTypes.GET_EVENT_LIST_SUCCESS:
      return getEventListSuccess(state, action);
    case actionTypes.GET_EVENT_LIST_FAIL:
      return getEventListFail(state, action);
    default:
      return state;
  }
};
