export const GET_EXPLOREMAP = 'GET_EXPLOREMAP';
export const GET_EXPLOREMAPLAYOUT = 'GET_EXPLOREMAPLAYOUT';
export const GET_EXPLOREMAP_START = 'GET_EXPLOREMAP_START';
export const GET_EXPLOREMAP_SUCCESS = 'GET_EXPLOREMAP_SUCCESS';
export const GET_EXPLOREMAP_FAIL = 'GET_EXPLOREMAP_FAIL';
export const UPDATE_SELECTED_CATEGORIES = 'UPDATE_SELECTED_CATEGORIES';
export const UPDATE_GEOJSON_DATA = 'UPDATE_GEOJSON_DATA';
export const UPDATE_PLACES = 'UPDATE_PLACES';
export const SET_MAP_STEP = 'SET_MAP_STEP';
export const SET_RESELECTED_STATUS = 'SET_RESELECTED_STATUS';
export const SHOULD_MAP_ZOOM = 'SHOULD_MAP_ZOOM';
export const SET_MAP_PANNING = 'SET_MAP_PANNING';
export const SET_CLEARING_ALL = 'SET_CLEARING_ALL';