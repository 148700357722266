import { createStore, applyMiddleware, compose } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
// import storage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';

// middlewares.
import sitecoreService from '../middleware/sitecoreApi';

// const persistConfig = {
//   key: 'root',
//   storage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();
// Create Store
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, sitecoreService))
);
// let persistor = persistStore(store);
export default store;
export { sagaMiddleware };
