import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  vadMain: {
    position: 'relative',
    zIndex: 2,
    paddingTop: '1px',
    backgroundColor: theme.palette.common.White,
    paddingBottom: '1px',
    marginTop: '-1px',
  },
  '@global': {
    '.comp-2:not(.comp-0 + .comp-1 + .comp-2), .comp-3:not(.comp-0 + .comp-1 + .comp-2 + .comp-3), .comp-4:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4), .comp-5:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5), .comp-6:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6), .comp-7:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7), .comp-8:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8), .comp-9:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9), .comp-10:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10), .comp-11:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11), .comp-12:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12), .comp-13:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12 + .comp-13), .comp-14:not(.comp-0 + .comp-1 + .comp-2 + .comp-3 + .comp-4 + .comp-5 + .comp-6 + .comp-7 + .comp-8 + .comp-9 + .comp-10 + .comp-11 + .comp-12 + .comp-13 + .comp-14)': {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
      visibiiity: 'hidden',
    },
  },
  banner: {
    // position: 'relative',
    // // zIndex: 1301,
    // paddingTop: '1px',
    // marginTop: '-2px',
    // backgroundColor: theme.palette.common.White,
    // paddingBottom: '1px',
  },
}));
export default useStyles;