import axios from 'axios';
/**
 * This will be used to trigger any http request.
 * @param {*} requestData will contain axios option object in which url is mandatory.
 *
 */
const fetchService = async requestData => {
  const defaultOptions = {};
  const defaultHeaders = {
    'Content-type': 'application/json',
  };

  const options = {
    method: 'GET',
    ...defaultOptions,
    ...requestData,
    ...requestData.additionalData,
    headers: {
      ...defaultHeaders,
      ...requestData.headers,
    },
  };
  try {

    const data = await axios(options); //changed {data} to data
    return data;
  } catch (error) {
    /** todo:: need to fix */
    return { error: error.message, data: error?.response?.data };
  }
};

export default fetchService;
