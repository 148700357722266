import * as actionTypes from './actionTypes';
/**
 *  Initial SmartList Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
};
/**
 * On Get SmartList Start Action
 */

// Pushing data dynamically for every dropped Smart List component
let consolidateData = [];

const getSmartListStart = state => {
  return { ...state, error: null, loading: true };
};

const resetSmartList = state => {
  consolidateData = [];
  return { ...state, data: consolidateData };
};

/**
 * On Get SmartList Success Action
 */
const getSmartListSuccess = (state, action) => {
  consolidateData.push({ id: action.uid, data: action.payload });
  return { ...state, data: consolidateData, error: null, loading: false };
};

/**
 * On Get SmartList Fail Action
 */
const getSmartListFail = (state, action) => {
  return { ...state, error: true, loading: false, message: action.message };
};

/**
 * Update SmartList Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_SMARTLIST:
      return resetSmartList(state);
    case actionTypes.GET_SMARTLIST_START:
      return getSmartListStart(state, action);
    case actionTypes.GET_SMARTLIST_SUCCESS:
      return getSmartListSuccess(state, action);
    case actionTypes.GET_SMARTLIST_FAIL:
      return getSmartListFail(state, action);
    default:
      return state;
  }
};
