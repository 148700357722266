
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { styles } from './Picture.style';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ImagePlaceholder from '../../../../assets/svg/ImagePlaceholder.svg';

// To show images in localhost use this code in browser console
// document.querySelectorAll('[src^="/-/"]').forEach((el) => {
//   let src = el.src;
//   el.src = `https://vaddev.projectuatserver.com/-/media${src.split('/-/media')[1]}`;
// });

const useStyles = makeStyles(styles);

const viewportSizes = {
  smallMaxWidth: 768,
  mediumMaxWidth: 1024,
  largeMaxWidth: 1680,
};
// const BaseUrl = 'https://vaddev.projectuatserver.com'
const BaseUrl = ''
const Picture = forwardRef(function Picture(props, ref) {
  const classes = useStyles();
  const isEditor = isExperienceEditorActive();
  const imgWrapper = useRef(null);
  const paralaxImgWrapper = useRef(null);
  const {
    image = {},
    mobileImage = {},
    minWidth = '1024',
    renditionNeeded = true,
    aspectRatio = {},
    disableLazyLoad = false,
    noRetina = false,
    forcedRatio = null,
    fallbackBackgroundNeeded = true,
    preferredSize = null,
  } = props.media;

  const paralaxScrollAmount = get(props, 'paralaxScrollAmount', -16.6);

  const [error, setError] = useState(false);
  const alternateAltText = get(props, 'alternateAltText', '');
  const aspectRatioMobile = get(aspectRatio, 'mobile', null);
  const aspectRatioDesktop = get(aspectRatio, 'desktop', aspectRatioMobile);

  const width95 = props.width || false;

  let imgSrc = '',
    mobileImgSrc = get(mobileImage, 'value.src', ''),
    hasParalaxDefault = true;

  if (
    preferredSize &&
    image.value &&
    image.value[aspectRatioDesktop] &&
    image.value[aspectRatioDesktop][preferredSize]
  ) {
    imgSrc = image.value[aspectRatioDesktop][preferredSize];
  } else if (
    image &&
    typeof image === 'object' &&
    Object.keys(image).length > 0
  ) {
    imgSrc = get(image, 'value.src', '');
  } else {
    imgSrc = image;
  }

  imgSrc = BaseUrl + imgSrc

  if (
    (typeof imgSrc === 'string' && imgSrc.match('.svg')) ||
    (typeof mobileImgSrc === 'string' && mobileImgSrc.match('.svg'))
  ) {
    hasParalaxDefault = false;
  }
  const hasParalax = props.hasOwnProperty('isParalax')
    ? props.isParalax
    : hasParalaxDefault;

  useImperativeHandle(ref, () => ({
    ref: paralaxImgWrapper,
  }));

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // convert paralaxScrollAmount to a positive integer without float value
    const paralaxScrollAmountInt = Math.abs(Math.floor(paralaxScrollAmount));

    if (imgWrapper.current && hasParalax) {
      setTimeout(() => {
        const imgParalaxTimeline = gsap.timeline({
          force3D: false,
          scrollTrigger: {
            trigger: paralaxImgWrapper.current,
            start: `${(window.androidHeight || window.innerHeight) * `0.${paralaxScrollAmountInt}`}px bottom`,
            end: '90% top',
            // start: "top bottom", // the default values
            // end: "bottom top",
            scrub: true,
          },
        });
        gsap.set(imgWrapper.current, {
          xPercent: -10,
          yPercent: 0,
        });
        imgParalaxTimeline.fromTo(
          imgWrapper.current,
          {
            yPercent: 0,
            ease: 'power1.out',
            force3D: false,
          },
          {
            yPercent: paralaxScrollAmount,
          }
        );
      }, 100);
    }
  }, []);

  if (isEmpty(image)) {
    return null;
  }
  if (isEditor) {
    return (
      <ConditionalWrapper
        condition={hasParalax}
        wrapper={children => (
          <div className={`${classes.root} ${props.className}`}>
            <div
              className={`img-to-animate-wrapper img-in-exp-editor ${classes.img
                } ${classes.paralaxImgWrapper} ${hasParalax && 'has-paralax'}`}
              ref={paralaxImgWrapper}
              style={{ height: '100%' }}
            >
              {children}
            </div>
          </div>
        )}
      >
        <div
          className={`${hasParalax ? 'img-to-animate' : `${classes.root} ${props.className}`
            }`}
          ref={!hasParalax && imgWrapper}
        >
          <Image
            field={image}
            style={!hasParalax && { height: '100%' }}
            className={!hasParalax && `img-in-exp-editor ${classes.img}`}
            data-locator="imageIn_EE"
          />
        </div>
      </ConditionalWrapper>
    );
  }

  //Lazy loading of images
  if (typeof window !== 'undefined' && process.browser) {
    if (isEditor) {
      require('lazysizes');
    } else {
      require('lazysizes').cfg.preloadAfterLoad = true;
    }
    require('picturefill');
  }

  // This is used when simple <img> tag is needed without media queries like logo img.
  if (!renditionNeeded) {
    const imgSrc = get(image, 'value.src', null)
    const imgProps = {
      'data-locator': 'img-tag-using-isNative',
      className: clsx(
        classes.img,
        'lazyload',
        error && 'fallback',
        props.className,
        !renditionNeeded && 'noRendition',
        !disableLazyLoad && 'disabledLazyLoad',
        error && fallbackBackgroundNeeded && classes.fallbackBackground
      ),
      alt: get(image, 'value.alt', '') || alternateAltText,
      'data-src': error ? ImagePlaceholder : imgSrc,
      src: error ? ImagePlaceholder : imgSrc,
      onError: () => setError(true),
      onClick: props.onClick,
      'data-sequence': props.sequence,
    };
    if (!disableLazyLoad) imgProps.src = imgSrc;
    return (
      <ConditionalWrapper
        condition={hasParalax}
        wrapper={children => (
          <div
            className={`img-to-animate-wrapper ${classes.paralaxImgWrapper
              } ${hasParalax && 'has-paralax'}`}
            ref={paralaxImgWrapper}
          >
            <div className="img-to-animate" ref={imgWrapper}>
              {children}
            </div>
          </div>
        )}
      >
        <img {...imgProps} itemProp="contentUrl" />
      </ConditionalWrapper>
    );
  }

  let advancedImage = {};
  const { value = {} } = image;
  const { value: mobileValue = {} } = mobileImage;
  if (!value) {
    return null;
  }
  let isAdvanced =
    value[aspectRatio.desktop] && !!value[aspectRatio.desktop].large;
  const hasMobileAdvancedImage =
    mobileValue[aspectRatio.mobile] && !!mobileValue[aspectRatio.mobile].small;
  if (hasMobileAdvancedImage) {
    isAdvanced = true;
    advancedImage = {
      small:
        (mobileValue[aspectRatio.mobile] &&
          mobileValue[aspectRatio.mobile].small) ||
        value[aspectRatio.desktop].small,
      small2x:
        (mobileValue[aspectRatio.mobile] &&
          mobileValue[aspectRatio.mobile].small2x) ||
        mobileValue[aspectRatio.desktop].small2x,
    };
  }

  if (!isEmpty(image.value) && isAdvanced) {
    advancedImage = {
      src: value.src,
      alt: value.alt || alternateAltText,
      small:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].small) ||
        value[aspectRatio.desktop].small,
      small2x:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].small2x) ||
        value[aspectRatio.desktop].small2x,
      medium:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].medium) ||
        value[aspectRatio.desktop].medium,
      medium2x:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].medium2x) ||
        value[aspectRatio.desktop].medium2x,
      large: value[aspectRatio.desktop] && value[aspectRatio.desktop].large,
      large2x: value[aspectRatio.desktop] && value[aspectRatio.desktop].large2x,
      xLarge: value[aspectRatio.desktop] && value[aspectRatio.desktop].xLarge,
      xLarge2x:
        value[aspectRatio.desktop] && value[aspectRatio.desktop].xLarge2x,
      ...advancedImage,
    };
  }

  const {
    src = '',
    alt = '',
    small = '',
    small2x = '',
    medium = '',
    medium2x = '',
    large = '',
    large2x = '',
    xLarge = '',
    xLarge2x = '',
  } = advancedImage;

  return (
    <>
      {/* This is DEFAULT (current) and will get removed when advance module changes are done for all images */}
      {!isAdvanced && renditionNeeded && (
        <ConditionalWrapper
          condition={hasParalax}
          wrapper={children => (
            <div
              className={`img-to-animate-wrapper ${classes.paralaxImgWrapper
                } has-paralax 
              ${forcedRatio === '3x2'
                  ? clsx(
                    classes.root,
                    classes['aspectRatio--m__3x2-card'],
                    classes['aspectRatio--d__3x2-card'],
                    'mobile_3x2-card',
                    'desktop_3x2-card'
                  )
                  : ''
                } ${forcedRatio === '2x1'
                  ? clsx(
                    classes.root,
                    classes['aspectRatio--m__2x1'],
                    classes['aspectRatio--d__2x1'],
                    'mobile_2x1',
                    'desktop_2x1'
                  )
                  : ''
                }`}
              ref={paralaxImgWrapper}
            >
              <div
                className={`img-to-animate ${clsx(
                  classes.root,
                  props.className
                )}`}
                ref={imgWrapper}
              >
                {children}
              </div>
            </div>
          )}
        >
          <picture
            itemScope
            itemType="https://schema.org/ImageObject"
            data-locator="picture-tag-old"
            className={`${!hasParalax &&
              clsx(classes.root, props.className, {
                [classes.width95]: width95,
              })}
            ${forcedRatio === '16x6'
                ? clsx(
                  classes.root,
                  classes['aspectRatio--m__16x6'],
                  classes['aspectRatio--d__16x6'],
                  'mobile_16x6',
                  'desktop_16x6'
                )
                : ''
              } ${forcedRatio === '1x1'
                ? clsx(
                  classes.root,
                  classes['aspectRatio--m__1x1'],
                  classes['aspectRatio--d__1x1'],
                  'mobile_1x1',
                  'desktop_1x1'
                )
                : ''
              }`}
          >
            {!error && get(mobileImage, 'value.src', null) && (
              <source
                srcSet={`${get(mobileImage, 'value.src')}`}
                media={`(max-width: ${minWidth - 0.01}px)`}
              />
            )}
            {!error && imgSrc && (
              <source
                srcSet={`${imgSrc}`}
                media={`(min-width: ${minWidth}px)`}
              />
            )}
            <img
              className={clsx(
                classes.img,
                'lazyload',
                error && 'fallback',
                fallbackBackgroundNeeded && classes.fallbackBackground
              )}
              itemProp="contentUrl"
              alt={""}
              data-src={error ? ImagePlaceholder : imgSrc}
              src={error ? ImagePlaceholder : imgSrc}
              onError={() => setError(true)}
              data-locator={props.dataLocator}
              onClick={props.onClick}
            />
          </picture>
        </ConditionalWrapper>
      )}

      {/*  Using <picture> , When Advance Image module data is provided */}
      {isAdvanced && !disableLazyLoad && !noRetina && (
        <ConditionalWrapper
          condition={hasParalax}
          wrapper={children => (
            <div
              className={`img-to-animate-wrapper ${classes.paralaxImgWrapper
                } ${hasParalax && 'has-paralax'} ${clsx(
                  classes.root,
                  aspectRatioMobile &&
                  classes[`aspectRatio--m__${aspectRatioMobile}`],
                  aspectRatioDesktop &&
                  classes[`aspectRatio--d__${aspectRatioDesktop}`],
                  `mobile_${aspectRatioMobile}`,
                  `desktop_${aspectRatioDesktop}`,
                  props.className
                )}`}
              ref={paralaxImgWrapper}
            >
              <div className="img-to-animate" ref={imgWrapper}>
                {children}
              </div>
            </div>
          )}
        >
          <picture
            className={
              !hasParalax ?
                clsx(
                  classes.root,
                  aspectRatioMobile &&
                  classes[`aspectRatio--m__${aspectRatioMobile}`],
                  aspectRatioDesktop &&
                  classes[`aspectRatio--d__${aspectRatioDesktop}`],
                  `mobile_${aspectRatioMobile}`,
                  `desktop_${aspectRatioDesktop}`,
                  props.className
                ) : undefined
            }
            itemScope
            itemType="https://schema.org/ImageObject"
            data-locator="picture-tag--using-isAdvanced1"
          >
            {!error && small && (
              <source
                srcSet={`${BaseUrl}${small} 1x, ${BaseUrl}${small2x} 2x`}
                media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
              />
            )}
            {!error && medium && (
              <source
                srcSet={`${BaseUrl}${medium} 1x, ${BaseUrl}${medium2x} 2x`}
                media={`(max-width: ${viewportSizes.mediumMaxWidth - 0.01}px)`}
              />
            )}
            {!error && large && (
              <source
                srcSet={`${BaseUrl}${large} 1x, ${BaseUrl}${large2x} 2x`}
                media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
              />
            )}
            {!error && xLarge && (
              <source
                srcSet={`${BaseUrl}${xLarge} 1x, ${BaseUrl}${xLarge2x} 2x`}
                media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
              />
            )}
            <img
              className={clsx(
                classes.img,
                'lazyload',
                error && 'fallback',
                fallbackBackgroundNeeded && classes.fallbackBackground
              )}
              loading="lazy"
              itemProp="contentUrl"
              alt={alt || alternateAltText}
              data-src={error ? ImagePlaceholder : BaseUrl + src}
              src={error ? ImagePlaceholder : BaseUrl + src}
              onError={() => setError(true)}
              data-locator="img-tag-using-isAdvanced1"
              onClick={props.onClick}
            />
          </picture>
        </ConditionalWrapper>
      )}

      {isAdvanced && disableLazyLoad && !noRetina && (
        <ConditionalWrapper
          condition={hasParalax}
          wrapper={children => (
            <div
              className={`img-to-animate-wrapper ${classes.paralaxImgWrapper
                } ${hasParalax && 'has-paralax'} ${clsx(
                  classes.root,
                  aspectRatioMobile &&
                  classes[`aspectRatio--m__${aspectRatioMobile}`],
                  aspectRatioDesktop &&
                  classes[`aspectRatio--d__${aspectRatioDesktop}`],
                  props.className
                )}`}
              ref={paralaxImgWrapper}
            >
              <div className="img-to-animate" ref={imgWrapper}>
                {children}
              </div>
            </div>
          )}
        >
          <picture
            className={
              !hasParalax ?
                clsx(
                  classes.root,
                  aspectRatioMobile &&
                  classes[`aspectRatio--m__${aspectRatioMobile}`],
                  aspectRatioDesktop &&
                  classes[`aspectRatio--d__${aspectRatioDesktop}`],
                  props.className
                ) : undefined
            }
            itemScope
            itemType="https://schema.org/ImageObject"
            data-locator="picture-tag--using-isAdvanced2"
          >
            {!error && small && (
              <source
                srcSet={`${BaseUrl}${small} 1x, ${BaseUrl}${small2x} 2x`}
                media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
              />
            )}
            {!error && medium && (
              <source
                srcSet={`${BaseUrl}${medium} 1x, ${BaseUrl}${medium2x} 2x`}
                media={`(max-width: ${viewportSizes.mediumMaxWidth - 0.01}px)`}
              />
            )}
            {!error && large && (
              <source
                srcSet={`${BaseUrl}${large} 1x, ${BaseUrl}${large2x} 2x`}
                media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
              />
            )}
            {!error && xLarge && (
              <source
                srcSet={`${BaseUrl}${xLarge} 1x, ${BaseUrl}${xLarge2x} 2x`}
                media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
              />
            )}
            <img
              className={clsx(
                classes.img,
                error && 'fallback',
                fallbackBackgroundNeeded && classes.fallbackBackground
              )}
              itemProp="contentUrl"
              alt={alt || alternateAltText}
              src={error ? ImagePlaceholder : BaseUrl + src}
              onError={() => setError(true)}
              data-locator="img-tag-using-isAdvanced2"
              onClick={props.onClick}
            />
          </picture>
        </ConditionalWrapper>
      )}

      {isAdvanced && disableLazyLoad && noRetina && (
        <ConditionalWrapper
          condition={hasParalax}
          wrapper={children => (
            <div
              className={`img-to-animate-wrapper ${classes.paralaxImgWrapper
                } has-paralax ${clsx(
                  classes.root,
                  aspectRatioMobile &&
                  classes[`aspectRatio--m__${aspectRatioMobile}`],
                  aspectRatioDesktop &&
                  classes[`aspectRatio--d__${aspectRatioDesktop}`],
                  props.className
                )}`}
              ref={paralaxImgWrapper}
            >
              <div className="img-to-animate" ref={imgWrapper}>
                {children}
              </div>
            </div>
          )}
        >
          <picture
            className={
              !hasParalax &&
              clsx(
                classes.root,
                aspectRatioMobile &&
                classes[`aspectRatio--m__${aspectRatioMobile}`],
                aspectRatioDesktop &&
                classes[`aspectRatio--d__${aspectRatioDesktop}`],
                props.className
              )
            }
            itemScope
            itemType="https://schema.org/ImageObject"
            data-locator="picture-tag--using-isAdvanced-noRetina"
          >
            {!error && small && (
              <source
                srcSet={`${BaseUrl}${small} 1x`}
                media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
              />
            )}
            {!error && medium && (
              <source
                srcSet={`${BaseUrl}${medium} 1x`}
                media={`(max-width: ${viewportSizes.mediumMaxWidth - 0.01}px)`}
              />
            )}
            {!error && large && (
              <source
                srcSet={`${BaseUrl}${large} 1x`}
                media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
              />
            )}
            {!error && xLarge && (
              <source
                srcSet={`${BaseUrl}${xLarge} 1x`}
                media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
              />
            )}
            <img
              className={clsx(
                classes.img,
                error && 'fallback',
                fallbackBackgroundNeeded && classes.fallbackBackground
              )}
              itemProp="contentUrl"
              alt={alt || alternateAltText}
              src={error ? ImagePlaceholder : BaseUrl + src}
              onError={() => setError(true)}
              data-locator="img-tag-using-isAdvanced3"
              onClick={props.onClick}
            />
          </picture>
        </ConditionalWrapper>
      )}
    </>
  );
});

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

Picture.propTypes = {
  alternateAltText: PropTypes.string,
};

Picture.defaultProps = {
  onClick: () => { },
};

export default Picture;