/* eslint-disable max-len */
import React from 'react';

const Language = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Language_icon_24x24</title>
    <g
      id="Language_icon_24x24"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-4">
        <path
          d="M6.04931046,4.00799603 C6.4131142,3.95386911 6.7593149,4.17939897 6.87080277,4.53724078 L6.87080277,4.53724078 L10.2506568,14.544778 C10.3856167,14.8154149 10.3533436,15.1401787 10.1685076,15.3777368 C9.98367164,15.6152958 9.68148039,15.7175366 9.39395771,15.6423592 C9.10936949,15.5671826 8.89226044,15.3266168 8.84238431,15.0259096 L8.84238431,15.0259096 L7.97394958,12.4759121 L4.35938343,12.4759121 L3.49094871,15.0259096 C3.44107258,15.3266168 3.22396352,15.5671826 2.9393753,15.6423592 C2.65185337,15.7175366 2.34966137,15.6152958 2.16482541,15.3777368 C1.97998945,15.1401787 1.94771711,14.8154149 2.08267618,14.544778 L2.08267618,14.544778 L3.04499574,11.7061016 C3.04792945,11.5497338 3.09780634,11.3993805 3.18582299,11.2730831 L3.18582299,11.2730831 L5.46253024,4.53724078 C5.54467947,4.26359699 5.77352413,4.05911607 6.04931046,4.00799603 Z M6.16666651,7.11129482 L4.87575002,10.936291 L7.45758299,10.936291 L6.16666651,7.11129482 Z"
          id="Combined-Shape"
          fill="#000000"
        ></path>
        <path
          d="M16.9801125,8.33661012 C16.949764,8.34227757 16.9194162,8.35077767 16.8918265,8.35927849 C16.5635127,8.43578445 16.3317619,8.73897419 16.3400389,9.0846666 L16.3400389,10.5354428 L12.8085985,10.5354428 C12.786527,10.5354428 12.7644555,10.5354428 12.742384,10.5354428 C12.7203125,10.5354428 12.698241,10.5354428 12.6761695,10.5354428 C12.2871594,10.5722787 12.0002299,10.9293061 12.0360959,11.3288361 C12.0719619,11.728366 12.4195884,12.023055 12.8085985,11.986219 L18.9665478,11.986219 C18.6878952,12.9354575 18.0478216,14.5505791 16.6269685,16.0211904 C15.4240716,14.8027654 14.8833198,13.7770209 14.8833198,13.7770209 C14.748132,13.5106678 14.46672,13.3576559 14.1770317,13.3916585 C13.9397634,13.4199943 13.7328428,13.5673388 13.6252441,13.7883551 C13.5176453,14.0065381 13.5259223,14.2672244 13.6473156,14.4797406 C13.6473156,14.4797406 14.2570407,15.6103262 15.5454648,16.9505939 C14.7426137,17.5683076 13.7797446,18.1406837 12.5878835,18.5600487 C12.3119897,18.6082189 12.0912747,18.8179017 12.0223014,19.0927556 C11.9533281,19.3704436 12.0471316,19.6622985 12.2650879,19.8408121 C12.4830442,20.0193258 12.7810095,20.050495 13.0293135,19.9201514 C14.4694794,19.4129463 15.6309921,18.7045598 16.5828255,17.9253341 C17.3967123,18.6223871 18.3678584,19.3109386 19.5624783,19.8974831 C19.9156224,20.0731634 20.3404992,19.9201514 20.5115529,19.5574574 C20.6826074,19.1947633 20.5336244,18.7583974 20.1804804,18.5827171 C19.176227,18.0896803 18.3430282,17.5258042 17.6422576,16.9505939 C19.4548796,15.0322825 20.1611677,12.9694601 20.4232669,11.986219 L21.2840555,11.986219 C21.5378778,11.9890524 21.7751468,11.8530421 21.9048164,11.6263583 C22.0317279,11.3996746 22.0317279,11.1219873 21.9048164,10.8953035 C21.7751468,10.6686197 21.5378778,10.5326094 21.2840555,10.5354428 L17.7526151,10.5354428 L17.7526151,9.0846666 C17.7608921,8.87498446 17.6781236,8.67380242 17.5319001,8.5321247 C17.3829178,8.38761433 17.1815148,8.3167751 16.9801125,8.33661012 Z"
          id="Path"
          fill="#000000"
        ></path>
        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
      </g>
    </g>
  </svg>
);

export default Language;
