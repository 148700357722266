import { canUseDOM } from '../global/utils/canUseDOM';

export const setLocalStorage = (key, value) => {
  if (canUseDOM) {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  }
};

export const getLocalStorage = key => {
  if (canUseDOM) {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }
  }
};
